// Generated by protoc-gen-grpc-ts-web. DO NOT EDIT!
/* eslint-disable */
/* tslint:disable */

import * as jspb from 'google-protobuf';
import * as grpcWeb from 'grpc-web';

export class UsersService {

	private client_ = new grpcWeb.GrpcWebClientBase({
		format: 'text',
	});

	private methodInfoLoginUser = new grpcWeb.MethodDescriptor<LoginUserReq, LoginUserRes>(
		"LoginUser",
		null,
		LoginUserReq,
		LoginUserRes,
		(req: LoginUserReq) => req.serializeBinary(),
		LoginUserRes.deserializeBinary
	);

	private methodInfoLogoutUser = new grpcWeb.MethodDescriptor<LogoutUserReq, LogoutUserRes>(
		"LogoutUser",
		null,
		LogoutUserReq,
		LogoutUserRes,
		(req: LogoutUserReq) => req.serializeBinary(),
		LogoutUserRes.deserializeBinary
	);

	constructor(
		private hostname: string,
		private defaultMetadata?: () => grpcWeb.Metadata,
	) { }

	loginUser(req: LoginUserReq.AsObject, metadata?: grpcWeb.Metadata): Promise<LoginUserRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = LoginUserReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.UsersService/LoginUser',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoLoginUser,
				(err: grpcWeb.Error, res: LoginUserRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	logoutUser(req: LogoutUserReq.AsObject, metadata?: grpcWeb.Metadata): Promise<LogoutUserRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = LogoutUserReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.UsersService/LogoutUser',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoLogoutUser,
				(err: grpcWeb.Error, res: LogoutUserRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

}




export declare namespace LoginUserReq {
	export type AsObject = {
		email: string,
		password: string,
	}
}

export class LoginUserReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, LoginUserReq.repeatedFields_, null);
	}


	getEmail(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setEmail(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getPassword(): string {
		return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setPassword(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		LoginUserReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): LoginUserReq.AsObject {
		let f: any;
		return {email: this.getEmail(),
			password: this.getPassword(),
			
		};
	}

	static serializeBinaryToWriter(message: LoginUserReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getEmail();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getPassword();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
	}

	static deserializeBinary(bytes: Uint8Array): LoginUserReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new LoginUserReq();
		return LoginUserReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: LoginUserReq, reader: jspb.BinaryReader): LoginUserReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setEmail(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setPassword(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace LoginUserRes {
	export type AsObject = {
		currentUser?: UserDTO.AsObject,
	}
}

export class LoginUserRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, LoginUserRes.repeatedFields_, null);
	}


	getCurrentUser(): UserDTO {
		return jspb.Message.getWrapperField(this, UserDTO, 1);
	}

	setCurrentUser(value?: UserDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		LoginUserRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): LoginUserRes.AsObject {
		let f: any;
		return {currentUser: (f = this.getCurrentUser()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: LoginUserRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getCurrentUser();
		if (field1 != null) {
			writer.writeMessage(1, field1, UserDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): LoginUserRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new LoginUserRes();
		return LoginUserRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: LoginUserRes, reader: jspb.BinaryReader): LoginUserRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new UserDTO();
				reader.readMessage(field1, UserDTO.deserializeBinaryFromReader);
				message.setCurrentUser(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace LogoutUserReq {
	export type AsObject = {
	}
}

export class LogoutUserReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, LogoutUserReq.repeatedFields_, null);
	}


	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		LogoutUserReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): LogoutUserReq.AsObject {
		let f: any;
		return {
		};
	}

	static serializeBinaryToWriter(message: LogoutUserReq, writer: jspb.BinaryWriter): void {
	}

	static deserializeBinary(bytes: Uint8Array): LogoutUserReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new LogoutUserReq();
		return LogoutUserReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: LogoutUserReq, reader: jspb.BinaryReader): LogoutUserReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace LogoutUserRes {
	export type AsObject = {
	}
}

export class LogoutUserRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, LogoutUserRes.repeatedFields_, null);
	}


	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		LogoutUserRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): LogoutUserRes.AsObject {
		let f: any;
		return {
		};
	}

	static serializeBinaryToWriter(message: LogoutUserRes, writer: jspb.BinaryWriter): void {
	}

	static deserializeBinary(bytes: Uint8Array): LogoutUserRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new LogoutUserRes();
		return LogoutUserRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: LogoutUserRes, reader: jspb.BinaryReader): LogoutUserRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace UserDTO {
	export type AsObject = {
		id: string,
		email: string,
	}
}

export class UserDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, UserDTO.repeatedFields_, null);
	}


	getId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getEmail(): string {
		return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setEmail(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		UserDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): UserDTO.AsObject {
		let f: any;
		return {id: this.getId(),
			email: this.getEmail(),
			
		};
	}

	static serializeBinaryToWriter(message: UserDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getEmail();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
	}

	static deserializeBinary(bytes: Uint8Array): UserDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new UserDTO();
		return UserDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: UserDTO, reader: jspb.BinaryReader): UserDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setEmail(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}


function LoginUserReqFromObject(obj: LoginUserReq.AsObject | undefined): LoginUserReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new LoginUserReq();
	message.setEmail(obj.email);
	message.setPassword(obj.password);
	return message;
}

function LoginUserResFromObject(obj: LoginUserRes.AsObject | undefined): LoginUserRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new LoginUserRes();
	message.setCurrentUser(UserDTOFromObject(obj.currentUser));
	return message;
}

function UserDTOFromObject(obj: UserDTO.AsObject | undefined): UserDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new UserDTO();
	message.setId(obj.id);
	message.setEmail(obj.email);
	return message;
}

function LogoutUserReqFromObject(obj: LogoutUserReq.AsObject | undefined): LogoutUserReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new LogoutUserReq();
	return message;
}

function LogoutUserResFromObject(obj: LogoutUserRes.AsObject | undefined): LogoutUserRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new LogoutUserRes();
	return message;
}

