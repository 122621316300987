import { UserManagerSettings, UserManager } from 'oidc-client';
import { UsersService } from '../../sdk/Users_pb';
import { environment } from '../../utils/Environment';

const apiUrl = environment.apiUrl;

const config: UserManagerSettings = {
  authority: environment.oidcAuthorityUrl,
  client_id: environment.oidcClientId,
  // Try out code
  response_type: 'id_token token',
  scope: 'openid',
  // URL to redirect to after login
  redirect_uri: `${location.hostname}/dashboard`,
  // URL to redirect to after logout
  post_logout_redirect_uri: `${location.hostname}/login`,
};

class UserService {
  private userManager = new UserManager(config);
  private userServiceGrpc = new UsersService(apiUrl, () => ({}));

  async signIn() {
    await this.userManager.signinRedirect();
  }

  async signOut() {
    await this.userManager.signoutRedirect();
  }

  async getAccessToken() {
    const user = await this.userManager.getUser();
    return user?.access_token;
  }

  async isAuthenticated() {
    return this.userManager.getUser;
  }
}

export const userService = new UserService();
