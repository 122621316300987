import React, { useState } from 'react';
import moment from 'moment';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { ExperimentDTO } from '../../../sdk/Experiment_pb';
import { experimentStore } from '../../../stores/ExperimentStore';
import { experimentService } from '../../../services/api/ExperimentsService';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import styles from './ExperimentList.scss';

export const ExperimentList = observer(() => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [experiments, setExperiments] = useState(experimentStore.experiments);

  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(parseInt(event.target.value));
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    experimentId: string,
  ) => {
    experimentService.deleteExperiment(experimentId).then(() => {
      experimentService.listExperiments();
    });
  };

  return (
    <Card>
      <Box minWidth={1050}>
        <Table className={styles.table}>
          <TableHead className={styles.head}>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Author</TableCell>
              <TableCell>Created on</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {experimentStore.experiments
              .slice(0, limit)
              .map((experiment: ExperimentDTO.AsObject) => (
                <TableRow hover key={experiment.id} className={styles.row}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography color="textPrimary" variant="body1">
                        <Link to={'/experiment/' + experiment.id}>{experiment.title}</Link>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Link to={'/users/' + experiment.author}> {experiment.author} </Link>
                  </TableCell>
                  <TableCell>{moment(experiment.createdDate).format('MMMM DD')}</TableCell>
                  <TableCell>
                    <IconButton aria-label="edit" color="primary">
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      onClick={(event) => handleDelete(event, experiment.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={experimentStore.experiments.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
});
