import React, { useEffect } from 'react';
import { TopBar } from '../../components/TopBar/TopBar';
import { SideBar } from '../../components/SideBar/SideBar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import styles from './Dashboard.scss';
import { experimentService } from '../../services/api/ExperimentsService';
import { ExperimentList } from './ExperimentList/ExperimentList';
import { Link } from 'react-router-dom';

export const Dashboard = () => {
  useEffect(() => {
    experimentService.listExperiments();
  }, []);

  return (
    <div className={styles.root}>
      <TopBar infoText={'Dashboard'}></TopBar>
      <SideBar />
      <Grid container spacing={8} className={styles.wrapper}>
        <Grid item xs={12} sm={12}>
          <Box className={styles.newExperiment} display="flex" justifyContent="flex-end">
            <div>
              <Button component={Link} to="/create" color="primary" variant="contained">
                New Experiment
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={8} className={styles.wrapper}>
        <Grid item xs={12} sm={12}>
          <Paper className={styles.experimentList}>
            <ExperimentList />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
