import {
  ExperimentsService,
  RetrieveExperimentRes,
  ListExperimentRes,
  CreateExperimentJobRes,
  PublishExperimentJobIDRes,
  RetrieveExperimentJobResultRes,
  RetrieveAllExperimentScriptRes,
  ExperimentJobInputDTO,
  ParameterTypeDTO,
  CreateExperimentWithScriptsReq,
  CreateExperimentWithScriptsRes,
  DeleteExperimentRes,
} from '../../sdk/Experiment_pb';
import { experimentStore } from '../../stores/ExperimentStore';
import { userService } from '../authentication/UserService';
import { action } from 'mobx';
import { environment } from '../../utils/Environment';

const apiUrl = environment.apiUrl;

class ExperimentsServiceClass {
  private service = new ExperimentsService(apiUrl, () => ({
    Authorization: `Bearer ${userService.getAccessToken()}`,
  }));

  @action
  async retrieveExperiment(): Promise<RetrieveExperimentRes.AsObject> {
    experimentStore.setIsLoading(true);
    let response;
    try {
      response = await this.service.retrieveExperiment({
        id: experimentStore.experimentId,
      });
      experimentStore.setExperiment(response.experiment);
    } catch (err) {
      if (err.message === 'Http response at 400 or 500 level') {
        userService.signOut();
      }
      return Promise.reject(err);
    } finally {
      experimentStore.setIsLoading(false);
    }
    return Promise.resolve(response);
  }

  @action
  async listExperiments(): Promise<ListExperimentRes.AsObject> {
    experimentStore.setIsLoading(true);
    let response: ListExperimentRes.AsObject;
    try {
      response = await this.service.listExperiments({
        pageRequest: {
          page: experimentStore.page,
          pageSize: experimentStore.pageSize,
        },
      });
    } catch (err) {
      if (err.message === 'Http response at 400 or 500 level') {
        userService.signOut();
      }
      return Promise.reject(err);
    } finally {
      experimentStore.setIsLoading(false);
    }
    experimentStore.setExperiments(response.items);
    const initCount: number | undefined = response.pageResult?.total;
    if (initCount === undefined) {
      experimentStore.setTotalCount(0);
    } else {
      experimentStore.setTotalCount(initCount);
    }
    return Promise.resolve(response);
  }

  @action
  async createExperimentJob(
    template: string,
    outputType: ParameterTypeDTO,
    experimentJobInputs: Array<ExperimentJobInputDTO.AsObject>,
  ): Promise<CreateExperimentJobRes.AsObject> {
    return await this.service.createExperimentJob({
      experimentId: experimentStore.experimentId,
      template: template,
      outputType: outputType,
      experimentJobInputs: experimentJobInputs,
    });
  }

  @action
  async publishExperimentJobID(
    experimentJobId: string,
  ): Promise<PublishExperimentJobIDRes.AsObject> {
    experimentStore.setExperimentJobId(experimentJobId);
    return await this.service.publishExperimentJobID({
      experimentJobId: experimentJobId,
    });
  }

  @action
  async submitExperimentJob(
    template: string,
    outputType: ParameterTypeDTO,
    experimentJobInputs: Array<ExperimentJobInputDTO.AsObject>,
  ): Promise<PublishExperimentJobIDRes.AsObject> {
    return this.createExperimentJob(
      template,
      outputType,
      experimentJobInputs,
    ).then((createExperimentJobRes) =>
      this.publishExperimentJobID(createExperimentJobRes.experimentJob!.id),
    );
  }

  @action
  async retrieveExperimentJobResult(): Promise<RetrieveExperimentJobResultRes.AsObject> {
    return await this.service.retrieveExperimentJobResult({
      experimentJobId: experimentStore.experimentJobId,
    });
  }

  @action
  async retrieveAllExperimentScripts(): Promise<RetrieveAllExperimentScriptRes.AsObject> {
    let response;
    try {
      response = await this.service.retrieveAllExperimentScript({
        experimentId: experimentStore.experimentId,
      });
      experimentStore.setScripts(response.templates);
    } catch (err) {
      experimentStore.setScripts([]);
      return Promise.reject(err);
    } finally {
    }
    return Promise.resolve(response);
  }

  @action
  async createExperimentWithScripts(
    createExperimentWithScriptsReq: CreateExperimentWithScriptsReq.AsObject,
  ): Promise<CreateExperimentWithScriptsRes.AsObject> {
    let response;
    try {
      response = await this.service.createExperimentWithScripts(createExperimentWithScriptsReq);
    } catch (err) {
      return Promise.reject(err);
    } finally {
    }
    return Promise.resolve(response);
  }

  @action
  async deleteExperiment(experimentId: string): Promise<DeleteExperimentRes.AsObject> {
    return await this.service.deleteExperiment({
      id: experimentId,
    });
  }
}

export const experimentService = new ExperimentsServiceClass();
