import React, { useState } from 'react';
import { TopBar } from '../../components/TopBar/TopBar';
import { SideBar } from '../../components/SideBar/SideBar';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import styles from './CreateExperiment.scss';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { CreateScript } from './CreateScript/CreateScript';
import { ExperimentJobInputDTO, ExperimentScriptTemplateDTO } from '../../sdk/Experiment_pb';
import { experimentService } from '../../services/api/ExperimentsService';
import { Redirect } from 'react-router-dom';

export const CreateExperiment = () => {
  const [redirect, setRedirect] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [title, setTitle] = useState('Untitled experiment');
  const [picDeviceId, setPicDeviceId] = useState('0');
  const [description, setDescription] = useState('');
  const [setupScript, setSetupScript] = useState('');
  const [scripts, setScripts] = useState<Array<ExperimentScriptTemplateDTO.AsObject>>([]);
  const newScript = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const newscript: ExperimentScriptTemplateDTO.AsObject = {
      id: '',
      name: 'new script',
      template: '',
      outputType: 1,
      inputs: [],
    };
    setScripts([...scripts, newscript]);
  };
  const updateScript = (
    prop: string,
    value: string | Array<ExperimentJobInputDTO.AsObject>,
    idx: number,
  ) => {
    const old = scripts[idx];
    const updated = { ...old, [prop]: value };
    const clone = [...scripts];
    clone[idx] = updated;
    setScripts(clone);
  };

  const newExperiment = () => {
    setSubmitted(true);
    experimentService
      .createExperimentWithScripts({
        title: title,
        author: 'Author', // TODO: find this variable based on login
        description: description,
        picDeviceId: picDeviceId,
        setupScript: setupScript,
        scripts: scripts,
      })
      .then(() => setRedirect(true));
  };

  return redirect ? (
    <Redirect to={'/dashboard'} />
  ) : (
    <div className={styles.root}>
      <TopBar infoText={'New Experiment'}></TopBar>
      <SideBar />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <Paper className={styles.newExperiment}>
            <Box className={styles.experimentEntry}>
              <Box fontSize={20}>Title</Box>
              <TextField
                variant="outlined"
                size="small"
                style={{ width: 300 }}
                onChange={(event) => setTitle(event.target.value)}
              />
            </Box>
            <Box className={styles.experimentEntry}>
              <Box fontSize={20}>Device ID</Box>
              <TextField
                variant="outlined"
                size="small"
                style={{ width: 300 }}
                onChange={(event) => setPicDeviceId(event.target.value)}
              />
            </Box>
            <Box className={styles.experimentEntry}>
              <Box fontSize={20}>Description</Box>
              <TextField
                variant="outlined"
                size="small"
                style={{ width: 600 }}
                multiline
                rows={6}
                onChange={(event) => setDescription(event.target.value)}
              />
            </Box>
            <Box className={styles.experimentEntry}>
              <Box fontSize={20}>Setup Script</Box>
              <TextField
                variant="outlined"
                size="small"
                style={{ width: 600 }}
                multiline
                rows={6}
                onChange={(event) => setSetupScript(event.target.value)}
              />
            </Box>
            <Paper className={styles.experimentEntry}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <Box className={styles.experimentEntry}>
                    <Box fontSize={20}>Scripts</Box>
                    {scripts.map((script, idx) => (
                      <CreateScript
                        key={idx}
                        name={script.name}
                        template={script.template}
                        outputType={script.outputType}
                        inputs={script.inputs}
                        scriptUpdate={(
                          prop: string,
                          value: string | Array<ExperimentJobInputDTO.AsObject>,
                        ) => updateScript(prop, value, idx)}
                      />
                    ))}
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={(event) => newScript(event)}
                    >
                      {'Add Script'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
            <Box className={styles.experimentEntry}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                disabled={submitted}
                onClick={newExperiment}
              >
                {'Create Experiment'}
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
