// Generated by protoc-gen-grpc-ts-web. DO NOT EDIT!
/* eslint-disable */
/* tslint:disable */

import * as jspb from 'google-protobuf';
import * as grpcWeb from 'grpc-web';

import * as googleProtobufTimestamp from 'google-protobuf/google/protobuf/timestamp_pb';
import * as core from './Core_pb';

export class ExperimentsService {

	private client_ = new grpcWeb.GrpcWebClientBase({
		format: 'text',
	});

	private methodInfoListExperiments = new grpcWeb.MethodDescriptor<ListExperimentReq, ListExperimentRes>(
		"ListExperiments",
		null,
		ListExperimentReq,
		ListExperimentRes,
		(req: ListExperimentReq) => req.serializeBinary(),
		ListExperimentRes.deserializeBinary
	);

	private methodInfoRetrieveExperiment = new grpcWeb.MethodDescriptor<RetrieveExperimentReq, RetrieveExperimentRes>(
		"RetrieveExperiment",
		null,
		RetrieveExperimentReq,
		RetrieveExperimentRes,
		(req: RetrieveExperimentReq) => req.serializeBinary(),
		RetrieveExperimentRes.deserializeBinary
	);

	private methodInfoCreateExperiment = new grpcWeb.MethodDescriptor<CreateExperimentReq, CreateExperimentRes>(
		"CreateExperiment",
		null,
		CreateExperimentReq,
		CreateExperimentRes,
		(req: CreateExperimentReq) => req.serializeBinary(),
		CreateExperimentRes.deserializeBinary
	);

	private methodInfoDeleteExperiment = new grpcWeb.MethodDescriptor<DeleteExperimentReq, DeleteExperimentRes>(
		"DeleteExperiment",
		null,
		DeleteExperimentReq,
		DeleteExperimentRes,
		(req: DeleteExperimentReq) => req.serializeBinary(),
		DeleteExperimentRes.deserializeBinary
	);

	private methodInfoCreateExperimentJob = new grpcWeb.MethodDescriptor<CreateExperimentJobReq, CreateExperimentJobRes>(
		"CreateExperimentJob",
		null,
		CreateExperimentJobReq,
		CreateExperimentJobRes,
		(req: CreateExperimentJobReq) => req.serializeBinary(),
		CreateExperimentJobRes.deserializeBinary
	);

	private methodInfoListExperimentJobs = new grpcWeb.MethodDescriptor<ListExperimentJobsReq, ListExperimentJobsRes>(
		"ListExperimentJobs",
		null,
		ListExperimentJobsReq,
		ListExperimentJobsRes,
		(req: ListExperimentJobsReq) => req.serializeBinary(),
		ListExperimentJobsRes.deserializeBinary
	);

	private methodInfoPublishExperimentJobID = new grpcWeb.MethodDescriptor<PublishExperimentJobIDReq, PublishExperimentJobIDRes>(
		"PublishExperimentJobID",
		null,
		PublishExperimentJobIDReq,
		PublishExperimentJobIDRes,
		(req: PublishExperimentJobIDReq) => req.serializeBinary(),
		PublishExperimentJobIDRes.deserializeBinary
	);

	private methodInfoRetrieveExperimentJob = new grpcWeb.MethodDescriptor<RetrieveExperimentJobReq, RetrieveExperimentJobRes>(
		"RetrieveExperimentJob",
		null,
		RetrieveExperimentJobReq,
		RetrieveExperimentJobRes,
		(req: RetrieveExperimentJobReq) => req.serializeBinary(),
		RetrieveExperimentJobRes.deserializeBinary
	);

	private methodInfoSubmitExperimentJobResult = new grpcWeb.MethodDescriptor<SubmitExperimentJobResultReq, SubmitExperimentJobResultRes>(
		"SubmitExperimentJobResult",
		null,
		SubmitExperimentJobResultReq,
		SubmitExperimentJobResultRes,
		(req: SubmitExperimentJobResultReq) => req.serializeBinary(),
		SubmitExperimentJobResultRes.deserializeBinary
	);

	private methodInfoRetrieveExperimentJobResult = new grpcWeb.MethodDescriptor<RetrieveExperimentJobResultReq, RetrieveExperimentJobResultRes>(
		"RetrieveExperimentJobResult",
		null,
		RetrieveExperimentJobResultReq,
		RetrieveExperimentJobResultRes,
		(req: RetrieveExperimentJobResultReq) => req.serializeBinary(),
		RetrieveExperimentJobResultRes.deserializeBinary
	);

	private methodInfoRetrieveExperimentScript = new grpcWeb.MethodDescriptor<RetrieveExperimentScriptReq, RetrieveExperimentScriptRes>(
		"RetrieveExperimentScript",
		null,
		RetrieveExperimentScriptReq,
		RetrieveExperimentScriptRes,
		(req: RetrieveExperimentScriptReq) => req.serializeBinary(),
		RetrieveExperimentScriptRes.deserializeBinary
	);

	private methodInfoRetrieveAllExperimentScript = new grpcWeb.MethodDescriptor<RetrieveAllExperimentScriptReq, RetrieveAllExperimentScriptRes>(
		"RetrieveAllExperimentScript",
		null,
		RetrieveAllExperimentScriptReq,
		RetrieveAllExperimentScriptRes,
		(req: RetrieveAllExperimentScriptReq) => req.serializeBinary(),
		RetrieveAllExperimentScriptRes.deserializeBinary
	);

	private methodInfoCreateExperimentScript = new grpcWeb.MethodDescriptor<CreateExperimentScriptReq, CreateExperimentScriptRes>(
		"CreateExperimentScript",
		null,
		CreateExperimentScriptReq,
		CreateExperimentScriptRes,
		(req: CreateExperimentScriptReq) => req.serializeBinary(),
		CreateExperimentScriptRes.deserializeBinary
	);

	private methodInfoCreateExperimentWithScripts = new grpcWeb.MethodDescriptor<CreateExperimentWithScriptsReq, CreateExperimentWithScriptsRes>(
		"CreateExperimentWithScripts",
		null,
		CreateExperimentWithScriptsReq,
		CreateExperimentWithScriptsRes,
		(req: CreateExperimentWithScriptsReq) => req.serializeBinary(),
		CreateExperimentWithScriptsRes.deserializeBinary
	);

	private methodInfoTestReply = new grpcWeb.MethodDescriptor<TestReplyReq, TestReplyRes>(
		"TestReply",
		null,
		TestReplyReq,
		TestReplyRes,
		(req: TestReplyReq) => req.serializeBinary(),
		TestReplyRes.deserializeBinary
	);

	constructor(
		private hostname: string,
		private defaultMetadata?: () => grpcWeb.Metadata,
	) { }

	listExperiments(req: ListExperimentReq.AsObject, metadata?: grpcWeb.Metadata): Promise<ListExperimentRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = ListExperimentReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/ListExperiments',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoListExperiments,
				(err: grpcWeb.Error, res: ListExperimentRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	retrieveExperiment(req: RetrieveExperimentReq.AsObject, metadata?: grpcWeb.Metadata): Promise<RetrieveExperimentRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = RetrieveExperimentReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/RetrieveExperiment',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoRetrieveExperiment,
				(err: grpcWeb.Error, res: RetrieveExperimentRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createExperiment(req: CreateExperimentReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateExperimentRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateExperimentReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/CreateExperiment',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateExperiment,
				(err: grpcWeb.Error, res: CreateExperimentRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	deleteExperiment(req: DeleteExperimentReq.AsObject, metadata?: grpcWeb.Metadata): Promise<DeleteExperimentRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = DeleteExperimentReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/DeleteExperiment',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoDeleteExperiment,
				(err: grpcWeb.Error, res: DeleteExperimentRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createExperimentJob(req: CreateExperimentJobReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateExperimentJobRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateExperimentJobReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/CreateExperimentJob',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateExperimentJob,
				(err: grpcWeb.Error, res: CreateExperimentJobRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	listExperimentJobs(req: ListExperimentJobsReq.AsObject, metadata?: grpcWeb.Metadata): Promise<ListExperimentJobsRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = ListExperimentJobsReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/ListExperimentJobs',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoListExperimentJobs,
				(err: grpcWeb.Error, res: ListExperimentJobsRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	publishExperimentJobID(req: PublishExperimentJobIDReq.AsObject, metadata?: grpcWeb.Metadata): Promise<PublishExperimentJobIDRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = PublishExperimentJobIDReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/PublishExperimentJobID',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoPublishExperimentJobID,
				(err: grpcWeb.Error, res: PublishExperimentJobIDRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	retrieveExperimentJob(req: RetrieveExperimentJobReq.AsObject, metadata?: grpcWeb.Metadata): Promise<RetrieveExperimentJobRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = RetrieveExperimentJobReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/RetrieveExperimentJob',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoRetrieveExperimentJob,
				(err: grpcWeb.Error, res: RetrieveExperimentJobRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	submitExperimentJobResult(req: SubmitExperimentJobResultReq.AsObject, metadata?: grpcWeb.Metadata): Promise<SubmitExperimentJobResultRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = SubmitExperimentJobResultReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/SubmitExperimentJobResult',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoSubmitExperimentJobResult,
				(err: grpcWeb.Error, res: SubmitExperimentJobResultRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	retrieveExperimentJobResult(req: RetrieveExperimentJobResultReq.AsObject, metadata?: grpcWeb.Metadata): Promise<RetrieveExperimentJobResultRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = RetrieveExperimentJobResultReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/RetrieveExperimentJobResult',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoRetrieveExperimentJobResult,
				(err: grpcWeb.Error, res: RetrieveExperimentJobResultRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	retrieveExperimentScript(req: RetrieveExperimentScriptReq.AsObject, metadata?: grpcWeb.Metadata): Promise<RetrieveExperimentScriptRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = RetrieveExperimentScriptReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/RetrieveExperimentScript',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoRetrieveExperimentScript,
				(err: grpcWeb.Error, res: RetrieveExperimentScriptRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	retrieveAllExperimentScript(req: RetrieveAllExperimentScriptReq.AsObject, metadata?: grpcWeb.Metadata): Promise<RetrieveAllExperimentScriptRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = RetrieveAllExperimentScriptReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/RetrieveAllExperimentScript',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoRetrieveAllExperimentScript,
				(err: grpcWeb.Error, res: RetrieveAllExperimentScriptRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createExperimentScript(req: CreateExperimentScriptReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateExperimentScriptRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateExperimentScriptReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/CreateExperimentScript',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateExperimentScript,
				(err: grpcWeb.Error, res: CreateExperimentScriptRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createExperimentWithScripts(req: CreateExperimentWithScriptsReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateExperimentWithScriptsRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateExperimentWithScriptsReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/CreateExperimentWithScripts',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateExperimentWithScripts,
				(err: grpcWeb.Error, res: CreateExperimentWithScriptsRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	testReply(req: TestReplyReq.AsObject, metadata?: grpcWeb.Metadata): Promise<TestReplyRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = TestReplyReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/OnlineLabs.Grpc.ExperimentsService/TestReply',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoTestReply,
				(err: grpcWeb.Error, res: TestReplyRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

}

export enum ParameterTypeDTO {
	BOOL_PARAMETER = 0,
	UINT8_PARAMETER = 1,
	STRING_PARAMETER = 2,
	UINT8_ARRAY_PARAMETER = 3,
}



export declare namespace ListExperimentReq {
	export type AsObject = {
		pageRequest?: core.PaginationRequestDTO.AsObject,
	}
}

export class ListExperimentReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListExperimentReq.repeatedFields_, null);
	}


	getPageRequest(): core.PaginationRequestDTO {
		return jspb.Message.getWrapperField(this, core.PaginationRequestDTO, 1);
	}

	setPageRequest(value?: core.PaginationRequestDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListExperimentReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListExperimentReq.AsObject {
		let f: any;
		return {pageRequest: (f = this.getPageRequest()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: ListExperimentReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getPageRequest();
		if (field1 != null) {
			writer.writeMessage(1, field1, core.PaginationRequestDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListExperimentReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListExperimentReq();
		return ListExperimentReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListExperimentReq, reader: jspb.BinaryReader): ListExperimentReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new core.PaginationRequestDTO();
				reader.readMessage(field1, core.PaginationRequestDTO.deserializeBinaryFromReader);
				message.setPageRequest(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace RetrieveExperimentReq {
	export type AsObject = {
		id: string,
	}
}

export class RetrieveExperimentReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, RetrieveExperimentReq.repeatedFields_, null);
	}


	getId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		RetrieveExperimentReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): RetrieveExperimentReq.AsObject {
		let f: any;
		return {id: this.getId(),
			
		};
	}

	static serializeBinaryToWriter(message: RetrieveExperimentReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): RetrieveExperimentReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new RetrieveExperimentReq();
		return RetrieveExperimentReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: RetrieveExperimentReq, reader: jspb.BinaryReader): RetrieveExperimentReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteExperimentReq {
	export type AsObject = {
		id: string,
	}
}

export class DeleteExperimentReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteExperimentReq.repeatedFields_, null);
	}


	getId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteExperimentReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteExperimentReq.AsObject {
		let f: any;
		return {id: this.getId(),
			
		};
	}

	static serializeBinaryToWriter(message: DeleteExperimentReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): DeleteExperimentReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteExperimentReq();
		return DeleteExperimentReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteExperimentReq, reader: jspb.BinaryReader): DeleteExperimentReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateExperimentReq {
	export type AsObject = {
		title: string,
		author: string,
		description: string,
		picDeviceId: string,
	}
}

export class CreateExperimentReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateExperimentReq.repeatedFields_, null);
	}


	getTitle(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setTitle(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getAuthor(): string {
		return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setAuthor(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getDescription(): string {
		return jspb.Message.getFieldWithDefault(this, 3, "");
	}

	setDescription(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 3, value);
	}

	getPicDeviceId(): string {
		return jspb.Message.getFieldWithDefault(this, 4, "");
	}

	setPicDeviceId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 4, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateExperimentReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateExperimentReq.AsObject {
		let f: any;
		return {title: this.getTitle(),
			author: this.getAuthor(),
			description: this.getDescription(),
			picDeviceId: this.getPicDeviceId(),
			
		};
	}

	static serializeBinaryToWriter(message: CreateExperimentReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getTitle();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getAuthor();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getDescription();
		if (field3.length > 0) {
			writer.writeString(3, field3);
		}
		const field4 = message.getPicDeviceId();
		if (field4.length > 0) {
			writer.writeString(4, field4);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateExperimentReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateExperimentReq();
		return CreateExperimentReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateExperimentReq, reader: jspb.BinaryReader): CreateExperimentReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setTitle(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setAuthor(field2);
				break;
			case 3:
				const field3 = reader.readString()
				message.setDescription(field3);
				break;
			case 4:
				const field4 = reader.readString()
				message.setPicDeviceId(field4);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateExperimentJobReq {
	export type AsObject = {
		experimentId: string,
		template: string,
		outputType: ParameterTypeDTO,
		experimentJobInputs: Array<ExperimentJobInputDTO.AsObject>,
	}
}

export class CreateExperimentJobReq extends jspb.Message {

	private static repeatedFields_ = [
		4,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateExperimentJobReq.repeatedFields_, null);
	}


	getExperimentId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setExperimentId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getTemplate(): string {
		return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setTemplate(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getOutputType(): ParameterTypeDTO {
		return jspb.Message.getFieldWithDefault(this, 3, 0);
	}

	setOutputType(value: ParameterTypeDTO): void {
		(jspb.Message as any).setProto3EnumField(this, 3, value);
	}

	getExperimentJobInputs(): Array<ExperimentJobInputDTO> {
		return jspb.Message.getRepeatedWrapperField(this, ExperimentJobInputDTO, 4);
	}

	setExperimentJobInputs(value: Array<ExperimentJobInputDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 4, value);
	}
	
	addExperimentJobInputs(value?: ExperimentJobInputDTO, index?: number): ExperimentJobInputDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 4, value, ExperimentJobInputDTO, index);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateExperimentJobReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateExperimentJobReq.AsObject {
		let f: any;
		return {experimentId: this.getExperimentId(),
			template: this.getTemplate(),
			outputType: this.getOutputType(),
			
			experimentJobInputs: this.getExperimentJobInputs().map((item) => item.toObject()),
		};
	}

	static serializeBinaryToWriter(message: CreateExperimentJobReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperimentId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getTemplate();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getOutputType();
		if (field3 != 0) {
			writer.writeEnum(3, field3);
		}
		const field4 = message.getExperimentJobInputs();
		if (field4.length > 0) {
			writer.writeRepeatedMessage(4, field4, ExperimentJobInputDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateExperimentJobReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateExperimentJobReq();
		return CreateExperimentJobReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateExperimentJobReq, reader: jspb.BinaryReader): CreateExperimentJobReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setExperimentId(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setTemplate(field2);
				break;
			case 3:
				const field3 = reader.readEnum()
				message.setOutputType(field3);
				break;
			case 4:
				const field4 = new ExperimentJobInputDTO();
				reader.readMessage(field4, ExperimentJobInputDTO.deserializeBinaryFromReader);
				message.addExperimentJobInputs(field4);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ListExperimentJobsReq {
	export type AsObject = {
		pageRequest?: core.PaginationRequestDTO.AsObject,
	}
}

export class ListExperimentJobsReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListExperimentJobsReq.repeatedFields_, null);
	}


	getPageRequest(): core.PaginationRequestDTO {
		return jspb.Message.getWrapperField(this, core.PaginationRequestDTO, 1);
	}

	setPageRequest(value?: core.PaginationRequestDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListExperimentJobsReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListExperimentJobsReq.AsObject {
		let f: any;
		return {pageRequest: (f = this.getPageRequest()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: ListExperimentJobsReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getPageRequest();
		if (field1 != null) {
			writer.writeMessage(1, field1, core.PaginationRequestDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListExperimentJobsReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListExperimentJobsReq();
		return ListExperimentJobsReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListExperimentJobsReq, reader: jspb.BinaryReader): ListExperimentJobsReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new core.PaginationRequestDTO();
				reader.readMessage(field1, core.PaginationRequestDTO.deserializeBinaryFromReader);
				message.setPageRequest(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace PublishExperimentJobIDReq {
	export type AsObject = {
		experimentJobId: string,
	}
}

export class PublishExperimentJobIDReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, PublishExperimentJobIDReq.repeatedFields_, null);
	}


	getExperimentJobId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setExperimentJobId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		PublishExperimentJobIDReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): PublishExperimentJobIDReq.AsObject {
		let f: any;
		return {experimentJobId: this.getExperimentJobId(),
			
		};
	}

	static serializeBinaryToWriter(message: PublishExperimentJobIDReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperimentJobId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): PublishExperimentJobIDReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new PublishExperimentJobIDReq();
		return PublishExperimentJobIDReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: PublishExperimentJobIDReq, reader: jspb.BinaryReader): PublishExperimentJobIDReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setExperimentJobId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace RetrieveExperimentJobReq {
	export type AsObject = {
		id: string,
	}
}

export class RetrieveExperimentJobReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, RetrieveExperimentJobReq.repeatedFields_, null);
	}


	getId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		RetrieveExperimentJobReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): RetrieveExperimentJobReq.AsObject {
		let f: any;
		return {id: this.getId(),
			
		};
	}

	static serializeBinaryToWriter(message: RetrieveExperimentJobReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): RetrieveExperimentJobReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new RetrieveExperimentJobReq();
		return RetrieveExperimentJobReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: RetrieveExperimentJobReq, reader: jspb.BinaryReader): RetrieveExperimentJobReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace SubmitExperimentJobResultReq {
	export type AsObject = {
		result?: ExperimentJobOutputDTO.AsObject,
	}
}

export class SubmitExperimentJobResultReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, SubmitExperimentJobResultReq.repeatedFields_, null);
	}


	getResult(): ExperimentJobOutputDTO {
		return jspb.Message.getWrapperField(this, ExperimentJobOutputDTO, 1);
	}

	setResult(value?: ExperimentJobOutputDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		SubmitExperimentJobResultReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): SubmitExperimentJobResultReq.AsObject {
		let f: any;
		return {result: (f = this.getResult()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: SubmitExperimentJobResultReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getResult();
		if (field1 != null) {
			writer.writeMessage(1, field1, ExperimentJobOutputDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): SubmitExperimentJobResultReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new SubmitExperimentJobResultReq();
		return SubmitExperimentJobResultReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: SubmitExperimentJobResultReq, reader: jspb.BinaryReader): SubmitExperimentJobResultReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentJobOutputDTO();
				reader.readMessage(field1, ExperimentJobOutputDTO.deserializeBinaryFromReader);
				message.setResult(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace RetrieveExperimentJobResultReq {
	export type AsObject = {
		experimentJobId: string,
	}
}

export class RetrieveExperimentJobResultReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, RetrieveExperimentJobResultReq.repeatedFields_, null);
	}


	getExperimentJobId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setExperimentJobId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		RetrieveExperimentJobResultReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): RetrieveExperimentJobResultReq.AsObject {
		let f: any;
		return {experimentJobId: this.getExperimentJobId(),
			
		};
	}

	static serializeBinaryToWriter(message: RetrieveExperimentJobResultReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperimentJobId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): RetrieveExperimentJobResultReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new RetrieveExperimentJobResultReq();
		return RetrieveExperimentJobResultReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: RetrieveExperimentJobResultReq, reader: jspb.BinaryReader): RetrieveExperimentJobResultReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setExperimentJobId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace RetrieveExperimentScriptReq {
	export type AsObject = {
		id: string,
	}
}

export class RetrieveExperimentScriptReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, RetrieveExperimentScriptReq.repeatedFields_, null);
	}


	getId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		RetrieveExperimentScriptReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): RetrieveExperimentScriptReq.AsObject {
		let f: any;
		return {id: this.getId(),
			
		};
	}

	static serializeBinaryToWriter(message: RetrieveExperimentScriptReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): RetrieveExperimentScriptReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new RetrieveExperimentScriptReq();
		return RetrieveExperimentScriptReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: RetrieveExperimentScriptReq, reader: jspb.BinaryReader): RetrieveExperimentScriptReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace RetrieveAllExperimentScriptReq {
	export type AsObject = {
		experimentId: string,
	}
}

export class RetrieveAllExperimentScriptReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, RetrieveAllExperimentScriptReq.repeatedFields_, null);
	}


	getExperimentId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setExperimentId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		RetrieveAllExperimentScriptReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): RetrieveAllExperimentScriptReq.AsObject {
		let f: any;
		return {experimentId: this.getExperimentId(),
			
		};
	}

	static serializeBinaryToWriter(message: RetrieveAllExperimentScriptReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperimentId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): RetrieveAllExperimentScriptReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new RetrieveAllExperimentScriptReq();
		return RetrieveAllExperimentScriptReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: RetrieveAllExperimentScriptReq, reader: jspb.BinaryReader): RetrieveAllExperimentScriptReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setExperimentId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateExperimentScriptReq {
	export type AsObject = {
		experimentId: string,
		name: string,
		template: string,
		outputType: ParameterTypeDTO,
		inputs: Array<ExperimentJobInputDTO.AsObject>,
	}
}

export class CreateExperimentScriptReq extends jspb.Message {

	private static repeatedFields_ = [
		5,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateExperimentScriptReq.repeatedFields_, null);
	}


	getExperimentId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setExperimentId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getName(): string {
		return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setName(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getTemplate(): string {
		return jspb.Message.getFieldWithDefault(this, 3, "");
	}

	setTemplate(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 3, value);
	}

	getOutputType(): ParameterTypeDTO {
		return jspb.Message.getFieldWithDefault(this, 4, 0);
	}

	setOutputType(value: ParameterTypeDTO): void {
		(jspb.Message as any).setProto3EnumField(this, 4, value);
	}

	getInputs(): Array<ExperimentJobInputDTO> {
		return jspb.Message.getRepeatedWrapperField(this, ExperimentJobInputDTO, 5);
	}

	setInputs(value: Array<ExperimentJobInputDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 5, value);
	}
	
	addInputs(value?: ExperimentJobInputDTO, index?: number): ExperimentJobInputDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 5, value, ExperimentJobInputDTO, index);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateExperimentScriptReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateExperimentScriptReq.AsObject {
		let f: any;
		return {experimentId: this.getExperimentId(),
			name: this.getName(),
			template: this.getTemplate(),
			outputType: this.getOutputType(),
			
			inputs: this.getInputs().map((item) => item.toObject()),
		};
	}

	static serializeBinaryToWriter(message: CreateExperimentScriptReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperimentId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getName();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getTemplate();
		if (field3.length > 0) {
			writer.writeString(3, field3);
		}
		const field4 = message.getOutputType();
		if (field4 != 0) {
			writer.writeEnum(4, field4);
		}
		const field5 = message.getInputs();
		if (field5.length > 0) {
			writer.writeRepeatedMessage(5, field5, ExperimentJobInputDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateExperimentScriptReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateExperimentScriptReq();
		return CreateExperimentScriptReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateExperimentScriptReq, reader: jspb.BinaryReader): CreateExperimentScriptReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setExperimentId(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setName(field2);
				break;
			case 3:
				const field3 = reader.readString()
				message.setTemplate(field3);
				break;
			case 4:
				const field4 = reader.readEnum()
				message.setOutputType(field4);
				break;
			case 5:
				const field5 = new ExperimentJobInputDTO();
				reader.readMessage(field5, ExperimentJobInputDTO.deserializeBinaryFromReader);
				message.addInputs(field5);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateExperimentWithScriptsReq {
	export type AsObject = {
		title: string,
		author: string,
		description: string,
		picDeviceId: string,
		setupScript: string,
		scripts: Array<ExperimentScriptTemplateDTO.AsObject>,
	}
}

export class CreateExperimentWithScriptsReq extends jspb.Message {

	private static repeatedFields_ = [
		6,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateExperimentWithScriptsReq.repeatedFields_, null);
	}


	getTitle(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setTitle(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getAuthor(): string {
		return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setAuthor(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getDescription(): string {
		return jspb.Message.getFieldWithDefault(this, 3, "");
	}

	setDescription(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 3, value);
	}

	getPicDeviceId(): string {
		return jspb.Message.getFieldWithDefault(this, 4, "");
	}

	setPicDeviceId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 4, value);
	}

	getSetupScript(): string {
		return jspb.Message.getFieldWithDefault(this, 5, "");
	}

	setSetupScript(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 5, value);
	}

	getScripts(): Array<ExperimentScriptTemplateDTO> {
		return jspb.Message.getRepeatedWrapperField(this, ExperimentScriptTemplateDTO, 6);
	}

	setScripts(value: Array<ExperimentScriptTemplateDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 6, value);
	}
	
	addScripts(value?: ExperimentScriptTemplateDTO, index?: number): ExperimentScriptTemplateDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 6, value, ExperimentScriptTemplateDTO, index);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateExperimentWithScriptsReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateExperimentWithScriptsReq.AsObject {
		let f: any;
		return {title: this.getTitle(),
			author: this.getAuthor(),
			description: this.getDescription(),
			picDeviceId: this.getPicDeviceId(),
			setupScript: this.getSetupScript(),
			
			scripts: this.getScripts().map((item) => item.toObject()),
		};
	}

	static serializeBinaryToWriter(message: CreateExperimentWithScriptsReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getTitle();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getAuthor();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getDescription();
		if (field3.length > 0) {
			writer.writeString(3, field3);
		}
		const field4 = message.getPicDeviceId();
		if (field4.length > 0) {
			writer.writeString(4, field4);
		}
		const field5 = message.getSetupScript();
		if (field5.length > 0) {
			writer.writeString(5, field5);
		}
		const field6 = message.getScripts();
		if (field6.length > 0) {
			writer.writeRepeatedMessage(6, field6, ExperimentScriptTemplateDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateExperimentWithScriptsReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateExperimentWithScriptsReq();
		return CreateExperimentWithScriptsReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateExperimentWithScriptsReq, reader: jspb.BinaryReader): CreateExperimentWithScriptsReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setTitle(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setAuthor(field2);
				break;
			case 3:
				const field3 = reader.readString()
				message.setDescription(field3);
				break;
			case 4:
				const field4 = reader.readString()
				message.setPicDeviceId(field4);
				break;
			case 5:
				const field5 = reader.readString()
				message.setSetupScript(field5);
				break;
			case 6:
				const field6 = new ExperimentScriptTemplateDTO();
				reader.readMessage(field6, ExperimentScriptTemplateDTO.deserializeBinaryFromReader);
				message.addScripts(field6);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace TestReplyReq {
	export type AsObject = {
		text: string,
	}
}

export class TestReplyReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, TestReplyReq.repeatedFields_, null);
	}


	getText(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setText(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		TestReplyReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): TestReplyReq.AsObject {
		let f: any;
		return {text: this.getText(),
			
		};
	}

	static serializeBinaryToWriter(message: TestReplyReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getText();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): TestReplyReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new TestReplyReq();
		return TestReplyReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: TestReplyReq, reader: jspb.BinaryReader): TestReplyReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setText(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ListExperimentRes {
	export type AsObject = {
		items: Array<ExperimentDTO.AsObject>,
		pageResult?: core.PaginationResultDTO.AsObject,
	}
}

export class ListExperimentRes extends jspb.Message {

	private static repeatedFields_ = [
		1,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListExperimentRes.repeatedFields_, null);
	}


	getItems(): Array<ExperimentDTO> {
		return jspb.Message.getRepeatedWrapperField(this, ExperimentDTO, 1);
	}

	setItems(value: Array<ExperimentDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 1, value);
	}
	
	addItems(value?: ExperimentDTO, index?: number): ExperimentDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 1, value, ExperimentDTO, index);
	}

	getPageResult(): core.PaginationResultDTO {
		return jspb.Message.getWrapperField(this, core.PaginationResultDTO, 2);
	}

	setPageResult(value?: core.PaginationResultDTO): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListExperimentRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListExperimentRes.AsObject {
		let f: any;
		return {
			items: this.getItems().map((item) => item.toObject()),pageResult: (f = this.getPageResult()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: ListExperimentRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getItems();
		if (field1.length > 0) {
			writer.writeRepeatedMessage(1, field1, ExperimentDTO.serializeBinaryToWriter);
		}
		const field2 = message.getPageResult();
		if (field2 != null) {
			writer.writeMessage(2, field2, core.PaginationResultDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListExperimentRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListExperimentRes();
		return ListExperimentRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListExperimentRes, reader: jspb.BinaryReader): ListExperimentRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentDTO();
				reader.readMessage(field1, ExperimentDTO.deserializeBinaryFromReader);
				message.addItems(field1);
				break;
			case 2:
				const field2 = new core.PaginationResultDTO();
				reader.readMessage(field2, core.PaginationResultDTO.deserializeBinaryFromReader);
				message.setPageResult(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace RetrieveExperimentRes {
	export type AsObject = {
		experiment?: ExperimentDTO.AsObject,
	}
}

export class RetrieveExperimentRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, RetrieveExperimentRes.repeatedFields_, null);
	}


	getExperiment(): ExperimentDTO {
		return jspb.Message.getWrapperField(this, ExperimentDTO, 1);
	}

	setExperiment(value?: ExperimentDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		RetrieveExperimentRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): RetrieveExperimentRes.AsObject {
		let f: any;
		return {experiment: (f = this.getExperiment()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: RetrieveExperimentRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperiment();
		if (field1 != null) {
			writer.writeMessage(1, field1, ExperimentDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): RetrieveExperimentRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new RetrieveExperimentRes();
		return RetrieveExperimentRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: RetrieveExperimentRes, reader: jspb.BinaryReader): RetrieveExperimentRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentDTO();
				reader.readMessage(field1, ExperimentDTO.deserializeBinaryFromReader);
				message.setExperiment(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateExperimentRes {
	export type AsObject = {
		experiment?: ExperimentDTO.AsObject,
	}
}

export class CreateExperimentRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateExperimentRes.repeatedFields_, null);
	}


	getExperiment(): ExperimentDTO {
		return jspb.Message.getWrapperField(this, ExperimentDTO, 1);
	}

	setExperiment(value?: ExperimentDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateExperimentRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateExperimentRes.AsObject {
		let f: any;
		return {experiment: (f = this.getExperiment()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: CreateExperimentRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperiment();
		if (field1 != null) {
			writer.writeMessage(1, field1, ExperimentDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateExperimentRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateExperimentRes();
		return CreateExperimentRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateExperimentRes, reader: jspb.BinaryReader): CreateExperimentRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentDTO();
				reader.readMessage(field1, ExperimentDTO.deserializeBinaryFromReader);
				message.setExperiment(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteExperimentRes {
	export type AsObject = {
		id: string,
	}
}

export class DeleteExperimentRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteExperimentRes.repeatedFields_, null);
	}


	getId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteExperimentRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteExperimentRes.AsObject {
		let f: any;
		return {id: this.getId(),
			
		};
	}

	static serializeBinaryToWriter(message: DeleteExperimentRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): DeleteExperimentRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteExperimentRes();
		return DeleteExperimentRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteExperimentRes, reader: jspb.BinaryReader): DeleteExperimentRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateExperimentJobRes {
	export type AsObject = {
		experimentJob?: ExperimentJobDTO.AsObject,
	}
}

export class CreateExperimentJobRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateExperimentJobRes.repeatedFields_, null);
	}


	getExperimentJob(): ExperimentJobDTO {
		return jspb.Message.getWrapperField(this, ExperimentJobDTO, 1);
	}

	setExperimentJob(value?: ExperimentJobDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateExperimentJobRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateExperimentJobRes.AsObject {
		let f: any;
		return {experimentJob: (f = this.getExperimentJob()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: CreateExperimentJobRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperimentJob();
		if (field1 != null) {
			writer.writeMessage(1, field1, ExperimentJobDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateExperimentJobRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateExperimentJobRes();
		return CreateExperimentJobRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateExperimentJobRes, reader: jspb.BinaryReader): CreateExperimentJobRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentJobDTO();
				reader.readMessage(field1, ExperimentJobDTO.deserializeBinaryFromReader);
				message.setExperimentJob(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ListExperimentJobsRes {
	export type AsObject = {
		items: Array<ExperimentJobDTO.AsObject>,
		pageResult?: core.PaginationResultDTO.AsObject,
	}
}

export class ListExperimentJobsRes extends jspb.Message {

	private static repeatedFields_ = [
		1,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListExperimentJobsRes.repeatedFields_, null);
	}


	getItems(): Array<ExperimentJobDTO> {
		return jspb.Message.getRepeatedWrapperField(this, ExperimentJobDTO, 1);
	}

	setItems(value: Array<ExperimentJobDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 1, value);
	}
	
	addItems(value?: ExperimentJobDTO, index?: number): ExperimentJobDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 1, value, ExperimentJobDTO, index);
	}

	getPageResult(): core.PaginationResultDTO {
		return jspb.Message.getWrapperField(this, core.PaginationResultDTO, 2);
	}

	setPageResult(value?: core.PaginationResultDTO): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListExperimentJobsRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListExperimentJobsRes.AsObject {
		let f: any;
		return {
			items: this.getItems().map((item) => item.toObject()),pageResult: (f = this.getPageResult()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: ListExperimentJobsRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getItems();
		if (field1.length > 0) {
			writer.writeRepeatedMessage(1, field1, ExperimentJobDTO.serializeBinaryToWriter);
		}
		const field2 = message.getPageResult();
		if (field2 != null) {
			writer.writeMessage(2, field2, core.PaginationResultDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListExperimentJobsRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListExperimentJobsRes();
		return ListExperimentJobsRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListExperimentJobsRes, reader: jspb.BinaryReader): ListExperimentJobsRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentJobDTO();
				reader.readMessage(field1, ExperimentJobDTO.deserializeBinaryFromReader);
				message.addItems(field1);
				break;
			case 2:
				const field2 = new core.PaginationResultDTO();
				reader.readMessage(field2, core.PaginationResultDTO.deserializeBinaryFromReader);
				message.setPageResult(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace PublishExperimentJobIDRes {
	export type AsObject = {
		experimentJobId: string,
	}
}

export class PublishExperimentJobIDRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, PublishExperimentJobIDRes.repeatedFields_, null);
	}


	getExperimentJobId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setExperimentJobId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		PublishExperimentJobIDRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): PublishExperimentJobIDRes.AsObject {
		let f: any;
		return {experimentJobId: this.getExperimentJobId(),
			
		};
	}

	static serializeBinaryToWriter(message: PublishExperimentJobIDRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperimentJobId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): PublishExperimentJobIDRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new PublishExperimentJobIDRes();
		return PublishExperimentJobIDRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: PublishExperimentJobIDRes, reader: jspb.BinaryReader): PublishExperimentJobIDRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setExperimentJobId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace RetrieveExperimentJobRes {
	export type AsObject = {
		experimentJob?: ExperimentJobDTO.AsObject,
	}
}

export class RetrieveExperimentJobRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, RetrieveExperimentJobRes.repeatedFields_, null);
	}


	getExperimentJob(): ExperimentJobDTO {
		return jspb.Message.getWrapperField(this, ExperimentJobDTO, 1);
	}

	setExperimentJob(value?: ExperimentJobDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		RetrieveExperimentJobRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): RetrieveExperimentJobRes.AsObject {
		let f: any;
		return {experimentJob: (f = this.getExperimentJob()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: RetrieveExperimentJobRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperimentJob();
		if (field1 != null) {
			writer.writeMessage(1, field1, ExperimentJobDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): RetrieveExperimentJobRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new RetrieveExperimentJobRes();
		return RetrieveExperimentJobRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: RetrieveExperimentJobRes, reader: jspb.BinaryReader): RetrieveExperimentJobRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentJobDTO();
				reader.readMessage(field1, ExperimentJobDTO.deserializeBinaryFromReader);
				message.setExperimentJob(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace SubmitExperimentJobResultRes {
	export type AsObject = {
		experimentJob?: ExperimentJobDTO.AsObject,
	}
}

export class SubmitExperimentJobResultRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, SubmitExperimentJobResultRes.repeatedFields_, null);
	}


	getExperimentJob(): ExperimentJobDTO {
		return jspb.Message.getWrapperField(this, ExperimentJobDTO, 1);
	}

	setExperimentJob(value?: ExperimentJobDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		SubmitExperimentJobResultRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): SubmitExperimentJobResultRes.AsObject {
		let f: any;
		return {experimentJob: (f = this.getExperimentJob()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: SubmitExperimentJobResultRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperimentJob();
		if (field1 != null) {
			writer.writeMessage(1, field1, ExperimentJobDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): SubmitExperimentJobResultRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new SubmitExperimentJobResultRes();
		return SubmitExperimentJobResultRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: SubmitExperimentJobResultRes, reader: jspb.BinaryReader): SubmitExperimentJobResultRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentJobDTO();
				reader.readMessage(field1, ExperimentJobDTO.deserializeBinaryFromReader);
				message.setExperimentJob(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace RetrieveExperimentJobResultRes {
	export type AsObject = {
		experimentJobOutput?: ExperimentJobOutputDTO.AsObject,
	}
}

export class RetrieveExperimentJobResultRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, RetrieveExperimentJobResultRes.repeatedFields_, null);
	}


	getExperimentJobOutput(): ExperimentJobOutputDTO {
		return jspb.Message.getWrapperField(this, ExperimentJobOutputDTO, 1);
	}

	setExperimentJobOutput(value?: ExperimentJobOutputDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		RetrieveExperimentJobResultRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): RetrieveExperimentJobResultRes.AsObject {
		let f: any;
		return {experimentJobOutput: (f = this.getExperimentJobOutput()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: RetrieveExperimentJobResultRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperimentJobOutput();
		if (field1 != null) {
			writer.writeMessage(1, field1, ExperimentJobOutputDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): RetrieveExperimentJobResultRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new RetrieveExperimentJobResultRes();
		return RetrieveExperimentJobResultRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: RetrieveExperimentJobResultRes, reader: jspb.BinaryReader): RetrieveExperimentJobResultRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentJobOutputDTO();
				reader.readMessage(field1, ExperimentJobOutputDTO.deserializeBinaryFromReader);
				message.setExperimentJobOutput(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace RetrieveExperimentScriptRes {
	export type AsObject = {
		template?: ExperimentScriptTemplateDTO.AsObject,
	}
}

export class RetrieveExperimentScriptRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, RetrieveExperimentScriptRes.repeatedFields_, null);
	}


	getTemplate(): ExperimentScriptTemplateDTO {
		return jspb.Message.getWrapperField(this, ExperimentScriptTemplateDTO, 1);
	}

	setTemplate(value?: ExperimentScriptTemplateDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		RetrieveExperimentScriptRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): RetrieveExperimentScriptRes.AsObject {
		let f: any;
		return {template: (f = this.getTemplate()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: RetrieveExperimentScriptRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getTemplate();
		if (field1 != null) {
			writer.writeMessage(1, field1, ExperimentScriptTemplateDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): RetrieveExperimentScriptRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new RetrieveExperimentScriptRes();
		return RetrieveExperimentScriptRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: RetrieveExperimentScriptRes, reader: jspb.BinaryReader): RetrieveExperimentScriptRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentScriptTemplateDTO();
				reader.readMessage(field1, ExperimentScriptTemplateDTO.deserializeBinaryFromReader);
				message.setTemplate(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace RetrieveAllExperimentScriptRes {
	export type AsObject = {
		templates: Array<ExperimentScriptTemplateDTO.AsObject>,
	}
}

export class RetrieveAllExperimentScriptRes extends jspb.Message {

	private static repeatedFields_ = [
		1,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, RetrieveAllExperimentScriptRes.repeatedFields_, null);
	}


	getTemplates(): Array<ExperimentScriptTemplateDTO> {
		return jspb.Message.getRepeatedWrapperField(this, ExperimentScriptTemplateDTO, 1);
	}

	setTemplates(value: Array<ExperimentScriptTemplateDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 1, value);
	}
	
	addTemplates(value?: ExperimentScriptTemplateDTO, index?: number): ExperimentScriptTemplateDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 1, value, ExperimentScriptTemplateDTO, index);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		RetrieveAllExperimentScriptRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): RetrieveAllExperimentScriptRes.AsObject {
		let f: any;
		return {
			templates: this.getTemplates().map((item) => item.toObject()),
		};
	}

	static serializeBinaryToWriter(message: RetrieveAllExperimentScriptRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getTemplates();
		if (field1.length > 0) {
			writer.writeRepeatedMessage(1, field1, ExperimentScriptTemplateDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): RetrieveAllExperimentScriptRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new RetrieveAllExperimentScriptRes();
		return RetrieveAllExperimentScriptRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: RetrieveAllExperimentScriptRes, reader: jspb.BinaryReader): RetrieveAllExperimentScriptRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentScriptTemplateDTO();
				reader.readMessage(field1, ExperimentScriptTemplateDTO.deserializeBinaryFromReader);
				message.addTemplates(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateExperimentScriptRes {
	export type AsObject = {
		script?: ExperimentScriptTemplateDTO.AsObject,
	}
}

export class CreateExperimentScriptRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateExperimentScriptRes.repeatedFields_, null);
	}


	getScript(): ExperimentScriptTemplateDTO {
		return jspb.Message.getWrapperField(this, ExperimentScriptTemplateDTO, 1);
	}

	setScript(value?: ExperimentScriptTemplateDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateExperimentScriptRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateExperimentScriptRes.AsObject {
		let f: any;
		return {script: (f = this.getScript()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: CreateExperimentScriptRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getScript();
		if (field1 != null) {
			writer.writeMessage(1, field1, ExperimentScriptTemplateDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateExperimentScriptRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateExperimentScriptRes();
		return CreateExperimentScriptRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateExperimentScriptRes, reader: jspb.BinaryReader): CreateExperimentScriptRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentScriptTemplateDTO();
				reader.readMessage(field1, ExperimentScriptTemplateDTO.deserializeBinaryFromReader);
				message.setScript(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateExperimentWithScriptsRes {
	export type AsObject = {
		experiment?: ExperimentDTO.AsObject,
	}
}

export class CreateExperimentWithScriptsRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateExperimentWithScriptsRes.repeatedFields_, null);
	}


	getExperiment(): ExperimentDTO {
		return jspb.Message.getWrapperField(this, ExperimentDTO, 1);
	}

	setExperiment(value?: ExperimentDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateExperimentWithScriptsRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateExperimentWithScriptsRes.AsObject {
		let f: any;
		return {experiment: (f = this.getExperiment()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: CreateExperimentWithScriptsRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperiment();
		if (field1 != null) {
			writer.writeMessage(1, field1, ExperimentDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateExperimentWithScriptsRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateExperimentWithScriptsRes();
		return CreateExperimentWithScriptsRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateExperimentWithScriptsRes, reader: jspb.BinaryReader): CreateExperimentWithScriptsRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ExperimentDTO();
				reader.readMessage(field1, ExperimentDTO.deserializeBinaryFromReader);
				message.setExperiment(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace TestReplyRes {
	export type AsObject = {
		text: string,
	}
}

export class TestReplyRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, TestReplyRes.repeatedFields_, null);
	}


	getText(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setText(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		TestReplyRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): TestReplyRes.AsObject {
		let f: any;
		return {text: this.getText(),
			
		};
	}

	static serializeBinaryToWriter(message: TestReplyRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getText();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): TestReplyRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new TestReplyRes();
		return TestReplyRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: TestReplyRes, reader: jspb.BinaryReader): TestReplyRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setText(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ExperimentDTO {
	export type AsObject = {
		id: string,
		title: string,
		author: string,
		createdDate: string,
		description: string,
		picDeviceId: string,
		serverID: string,
	}
}

export class ExperimentDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ExperimentDTO.repeatedFields_, null);
	}


	getId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getTitle(): string {
		return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setTitle(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getAuthor(): string {
		return jspb.Message.getFieldWithDefault(this, 3, "");
	}

	setAuthor(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 3, value);
	}

	getCreatedDate(): string {
		return jspb.Message.getFieldWithDefault(this, 4, "");
	}

	setCreatedDate(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 4, value);
	}

	getDescription(): string {
		return jspb.Message.getFieldWithDefault(this, 5, "");
	}

	setDescription(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 5, value);
	}

	getPicDeviceId(): string {
		return jspb.Message.getFieldWithDefault(this, 6, "");
	}

	setPicDeviceId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 6, value);
	}

	getServerID(): string {
		return jspb.Message.getFieldWithDefault(this, 7, "");
	}

	setServerID(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 7, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ExperimentDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ExperimentDTO.AsObject {
		let f: any;
		return {id: this.getId(),
			title: this.getTitle(),
			author: this.getAuthor(),
			createdDate: this.getCreatedDate(),
			description: this.getDescription(),
			picDeviceId: this.getPicDeviceId(),
			serverID: this.getServerID(),
			
		};
	}

	static serializeBinaryToWriter(message: ExperimentDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getTitle();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getAuthor();
		if (field3.length > 0) {
			writer.writeString(3, field3);
		}
		const field4 = message.getCreatedDate();
		if (field4.length > 0) {
			writer.writeString(4, field4);
		}
		const field5 = message.getDescription();
		if (field5.length > 0) {
			writer.writeString(5, field5);
		}
		const field6 = message.getPicDeviceId();
		if (field6.length > 0) {
			writer.writeString(6, field6);
		}
		const field7 = message.getServerID();
		if (field7.length > 0) {
			writer.writeString(7, field7);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ExperimentDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ExperimentDTO();
		return ExperimentDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ExperimentDTO, reader: jspb.BinaryReader): ExperimentDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setTitle(field2);
				break;
			case 3:
				const field3 = reader.readString()
				message.setAuthor(field3);
				break;
			case 4:
				const field4 = reader.readString()
				message.setCreatedDate(field4);
				break;
			case 5:
				const field5 = reader.readString()
				message.setDescription(field5);
				break;
			case 6:
				const field6 = reader.readString()
				message.setPicDeviceId(field6);
				break;
			case 7:
				const field7 = reader.readString()
				message.setServerID(field7);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ExperimentScriptTemplateDTO {
	export type AsObject = {
		id: string,
		name: string,
		template: string,
		outputType: ParameterTypeDTO,
		inputs: Array<ExperimentJobInputDTO.AsObject>,
	}
}

export class ExperimentScriptTemplateDTO extends jspb.Message {

	private static repeatedFields_ = [
		5,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ExperimentScriptTemplateDTO.repeatedFields_, null);
	}


	getId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getName(): string {
		return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setName(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getTemplate(): string {
		return jspb.Message.getFieldWithDefault(this, 3, "");
	}

	setTemplate(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 3, value);
	}

	getOutputType(): ParameterTypeDTO {
		return jspb.Message.getFieldWithDefault(this, 4, 0);
	}

	setOutputType(value: ParameterTypeDTO): void {
		(jspb.Message as any).setProto3EnumField(this, 4, value);
	}

	getInputs(): Array<ExperimentJobInputDTO> {
		return jspb.Message.getRepeatedWrapperField(this, ExperimentJobInputDTO, 5);
	}

	setInputs(value: Array<ExperimentJobInputDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 5, value);
	}
	
	addInputs(value?: ExperimentJobInputDTO, index?: number): ExperimentJobInputDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 5, value, ExperimentJobInputDTO, index);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ExperimentScriptTemplateDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ExperimentScriptTemplateDTO.AsObject {
		let f: any;
		return {id: this.getId(),
			name: this.getName(),
			template: this.getTemplate(),
			outputType: this.getOutputType(),
			
			inputs: this.getInputs().map((item) => item.toObject()),
		};
	}

	static serializeBinaryToWriter(message: ExperimentScriptTemplateDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getName();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getTemplate();
		if (field3.length > 0) {
			writer.writeString(3, field3);
		}
		const field4 = message.getOutputType();
		if (field4 != 0) {
			writer.writeEnum(4, field4);
		}
		const field5 = message.getInputs();
		if (field5.length > 0) {
			writer.writeRepeatedMessage(5, field5, ExperimentJobInputDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ExperimentScriptTemplateDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ExperimentScriptTemplateDTO();
		return ExperimentScriptTemplateDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ExperimentScriptTemplateDTO, reader: jspb.BinaryReader): ExperimentScriptTemplateDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setName(field2);
				break;
			case 3:
				const field3 = reader.readString()
				message.setTemplate(field3);
				break;
			case 4:
				const field4 = reader.readEnum()
				message.setOutputType(field4);
				break;
			case 5:
				const field5 = new ExperimentJobInputDTO();
				reader.readMessage(field5, ExperimentJobInputDTO.deserializeBinaryFromReader);
				message.addInputs(field5);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ExperimentJobDTO {
	export type AsObject = {
		id: string,
		template: string,
		outputType: ParameterTypeDTO,
		experimentJobInputs: Array<ExperimentJobInputDTO.AsObject>,
		experimentJobOutput?: ExperimentJobOutputDTO.AsObject,
	}
}

export class ExperimentJobDTO extends jspb.Message {

	private static repeatedFields_ = [
		4,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ExperimentJobDTO.repeatedFields_, null);
	}


	getId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getTemplate(): string {
		return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setTemplate(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getOutputType(): ParameterTypeDTO {
		return jspb.Message.getFieldWithDefault(this, 3, 0);
	}

	setOutputType(value: ParameterTypeDTO): void {
		(jspb.Message as any).setProto3EnumField(this, 3, value);
	}

	getExperimentJobInputs(): Array<ExperimentJobInputDTO> {
		return jspb.Message.getRepeatedWrapperField(this, ExperimentJobInputDTO, 4);
	}

	setExperimentJobInputs(value: Array<ExperimentJobInputDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 4, value);
	}
	
	addExperimentJobInputs(value?: ExperimentJobInputDTO, index?: number): ExperimentJobInputDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 4, value, ExperimentJobInputDTO, index);
	}

	getExperimentJobOutput(): ExperimentJobOutputDTO {
		return jspb.Message.getWrapperField(this, ExperimentJobOutputDTO, 5);
	}

	setExperimentJobOutput(value?: ExperimentJobOutputDTO): void {
		(jspb.Message as any).setWrapperField(this, 5, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ExperimentJobDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ExperimentJobDTO.AsObject {
		let f: any;
		return {id: this.getId(),
			template: this.getTemplate(),
			outputType: this.getOutputType(),
			
			experimentJobInputs: this.getExperimentJobInputs().map((item) => item.toObject()),experimentJobOutput: (f = this.getExperimentJobOutput()) && f.toObject(),
			
		};
	}

	static serializeBinaryToWriter(message: ExperimentJobDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getTemplate();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getOutputType();
		if (field3 != 0) {
			writer.writeEnum(3, field3);
		}
		const field4 = message.getExperimentJobInputs();
		if (field4.length > 0) {
			writer.writeRepeatedMessage(4, field4, ExperimentJobInputDTO.serializeBinaryToWriter);
		}
		const field5 = message.getExperimentJobOutput();
		if (field5 != null) {
			writer.writeMessage(5, field5, ExperimentJobOutputDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ExperimentJobDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ExperimentJobDTO();
		return ExperimentJobDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ExperimentJobDTO, reader: jspb.BinaryReader): ExperimentJobDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setTemplate(field2);
				break;
			case 3:
				const field3 = reader.readEnum()
				message.setOutputType(field3);
				break;
			case 4:
				const field4 = new ExperimentJobInputDTO();
				reader.readMessage(field4, ExperimentJobInputDTO.deserializeBinaryFromReader);
				message.addExperimentJobInputs(field4);
				break;
			case 5:
				const field5 = new ExperimentJobOutputDTO();
				reader.readMessage(field5, ExperimentJobOutputDTO.deserializeBinaryFromReader);
				message.setExperimentJobOutput(field5);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ExperimentJobInputDTO {
	export type AsObject = {
		key: string,
		value: string,
		parameterType: ParameterTypeDTO,
	}
}

export class ExperimentJobInputDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ExperimentJobInputDTO.repeatedFields_, null);
	}


	getKey(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setKey(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getValue(): string {
		return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setValue(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getParameterType(): ParameterTypeDTO {
		return jspb.Message.getFieldWithDefault(this, 3, 0);
	}

	setParameterType(value: ParameterTypeDTO): void {
		(jspb.Message as any).setProto3EnumField(this, 3, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ExperimentJobInputDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ExperimentJobInputDTO.AsObject {
		let f: any;
		return {key: this.getKey(),
			value: this.getValue(),
			parameterType: this.getParameterType(),
			
		};
	}

	static serializeBinaryToWriter(message: ExperimentJobInputDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getKey();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getValue();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getParameterType();
		if (field3 != 0) {
			writer.writeEnum(3, field3);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ExperimentJobInputDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ExperimentJobInputDTO();
		return ExperimentJobInputDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ExperimentJobInputDTO, reader: jspb.BinaryReader): ExperimentJobInputDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setKey(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setValue(field2);
				break;
			case 3:
				const field3 = reader.readEnum()
				message.setParameterType(field3);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ExperimentJobOutputDTO {
	export type AsObject = {
		experimentJobId: string,
		rawText: string,
	}
}

export class ExperimentJobOutputDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ExperimentJobOutputDTO.repeatedFields_, null);
	}


	getExperimentJobId(): string {
		return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setExperimentJobId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getRawText(): string {
		return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setRawText(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ExperimentJobOutputDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ExperimentJobOutputDTO.AsObject {
		let f: any;
		return {experimentJobId: this.getExperimentJobId(),
			rawText: this.getRawText(),
			
		};
	}

	static serializeBinaryToWriter(message: ExperimentJobOutputDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getExperimentJobId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getRawText();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ExperimentJobOutputDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ExperimentJobOutputDTO();
		return ExperimentJobOutputDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ExperimentJobOutputDTO, reader: jspb.BinaryReader): ExperimentJobOutputDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setExperimentJobId(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setRawText(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}


function ListExperimentReqFromObject(obj: ListExperimentReq.AsObject | undefined): ListExperimentReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListExperimentReq();
	message.setPageRequest(PaginationRequestDTOFromObject(obj.pageRequest));
	return message;
}

function PaginationRequestDTOFromObject(obj: core.PaginationRequestDTO.AsObject | undefined): core.PaginationRequestDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.PaginationRequestDTO();
	message.setPage(obj.page);
	message.setPageSize(obj.pageSize);
	return message;
}

function RetrieveExperimentReqFromObject(obj: RetrieveExperimentReq.AsObject | undefined): RetrieveExperimentReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new RetrieveExperimentReq();
	message.setId(obj.id);
	return message;
}

function DeleteExperimentReqFromObject(obj: DeleteExperimentReq.AsObject | undefined): DeleteExperimentReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteExperimentReq();
	message.setId(obj.id);
	return message;
}

function CreateExperimentReqFromObject(obj: CreateExperimentReq.AsObject | undefined): CreateExperimentReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateExperimentReq();
	message.setTitle(obj.title);
	message.setAuthor(obj.author);
	message.setDescription(obj.description);
	message.setPicDeviceId(obj.picDeviceId);
	return message;
}

function CreateExperimentJobReqFromObject(obj: CreateExperimentJobReq.AsObject | undefined): CreateExperimentJobReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateExperimentJobReq();
	message.setExperimentId(obj.experimentId);
	message.setTemplate(obj.template);
	message.setOutputType(obj.outputType);
	(obj.experimentJobInputs || [])
		.map((item) => ExperimentJobInputDTOFromObject(item))
		.forEach((item) => message.addExperimentJobInputs(item));
	return message;
}

function ExperimentJobInputDTOFromObject(obj: ExperimentJobInputDTO.AsObject | undefined): ExperimentJobInputDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ExperimentJobInputDTO();
	message.setKey(obj.key);
	message.setValue(obj.value);
	message.setParameterType(obj.parameterType);
	return message;
}

function ListExperimentJobsReqFromObject(obj: ListExperimentJobsReq.AsObject | undefined): ListExperimentJobsReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListExperimentJobsReq();
	message.setPageRequest(PaginationRequestDTOFromObject(obj.pageRequest));
	return message;
}

function PublishExperimentJobIDReqFromObject(obj: PublishExperimentJobIDReq.AsObject | undefined): PublishExperimentJobIDReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new PublishExperimentJobIDReq();
	message.setExperimentJobId(obj.experimentJobId);
	return message;
}

function RetrieveExperimentJobReqFromObject(obj: RetrieveExperimentJobReq.AsObject | undefined): RetrieveExperimentJobReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new RetrieveExperimentJobReq();
	message.setId(obj.id);
	return message;
}

function SubmitExperimentJobResultReqFromObject(obj: SubmitExperimentJobResultReq.AsObject | undefined): SubmitExperimentJobResultReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new SubmitExperimentJobResultReq();
	message.setResult(ExperimentJobOutputDTOFromObject(obj.result));
	return message;
}

function ExperimentJobOutputDTOFromObject(obj: ExperimentJobOutputDTO.AsObject | undefined): ExperimentJobOutputDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ExperimentJobOutputDTO();
	message.setExperimentJobId(obj.experimentJobId);
	message.setRawText(obj.rawText);
	return message;
}

function RetrieveExperimentJobResultReqFromObject(obj: RetrieveExperimentJobResultReq.AsObject | undefined): RetrieveExperimentJobResultReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new RetrieveExperimentJobResultReq();
	message.setExperimentJobId(obj.experimentJobId);
	return message;
}

function RetrieveExperimentScriptReqFromObject(obj: RetrieveExperimentScriptReq.AsObject | undefined): RetrieveExperimentScriptReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new RetrieveExperimentScriptReq();
	message.setId(obj.id);
	return message;
}

function RetrieveAllExperimentScriptReqFromObject(obj: RetrieveAllExperimentScriptReq.AsObject | undefined): RetrieveAllExperimentScriptReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new RetrieveAllExperimentScriptReq();
	message.setExperimentId(obj.experimentId);
	return message;
}

function CreateExperimentScriptReqFromObject(obj: CreateExperimentScriptReq.AsObject | undefined): CreateExperimentScriptReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateExperimentScriptReq();
	message.setExperimentId(obj.experimentId);
	message.setName(obj.name);
	message.setTemplate(obj.template);
	message.setOutputType(obj.outputType);
	(obj.inputs || [])
		.map((item) => ExperimentJobInputDTOFromObject(item))
		.forEach((item) => message.addInputs(item));
	return message;
}

function CreateExperimentWithScriptsReqFromObject(obj: CreateExperimentWithScriptsReq.AsObject | undefined): CreateExperimentWithScriptsReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateExperimentWithScriptsReq();
	message.setTitle(obj.title);
	message.setAuthor(obj.author);
	message.setDescription(obj.description);
	message.setPicDeviceId(obj.picDeviceId);
	message.setSetupScript(obj.setupScript);
	(obj.scripts || [])
		.map((item) => ExperimentScriptTemplateDTOFromObject(item))
		.forEach((item) => message.addScripts(item));
	return message;
}

function ExperimentScriptTemplateDTOFromObject(obj: ExperimentScriptTemplateDTO.AsObject | undefined): ExperimentScriptTemplateDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ExperimentScriptTemplateDTO();
	message.setId(obj.id);
	message.setName(obj.name);
	message.setTemplate(obj.template);
	message.setOutputType(obj.outputType);
	(obj.inputs || [])
		.map((item) => ExperimentJobInputDTOFromObject(item))
		.forEach((item) => message.addInputs(item));
	return message;
}

function TestReplyReqFromObject(obj: TestReplyReq.AsObject | undefined): TestReplyReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new TestReplyReq();
	message.setText(obj.text);
	return message;
}

function ListExperimentResFromObject(obj: ListExperimentRes.AsObject | undefined): ListExperimentRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListExperimentRes();
	(obj.items || [])
		.map((item) => ExperimentDTOFromObject(item))
		.forEach((item) => message.addItems(item));
	message.setPageResult(PaginationResultDTOFromObject(obj.pageResult));
	return message;
}

function ExperimentDTOFromObject(obj: ExperimentDTO.AsObject | undefined): ExperimentDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ExperimentDTO();
	message.setId(obj.id);
	message.setTitle(obj.title);
	message.setAuthor(obj.author);
	message.setCreatedDate(obj.createdDate);
	message.setDescription(obj.description);
	message.setPicDeviceId(obj.picDeviceId);
	message.setServerID(obj.serverID);
	return message;
}

function PaginationResultDTOFromObject(obj: core.PaginationResultDTO.AsObject | undefined): core.PaginationResultDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.PaginationResultDTO();
	message.setPage(obj.page);
	message.setTotal(obj.total);
	return message;
}

function RetrieveExperimentResFromObject(obj: RetrieveExperimentRes.AsObject | undefined): RetrieveExperimentRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new RetrieveExperimentRes();
	message.setExperiment(ExperimentDTOFromObject(obj.experiment));
	return message;
}

function CreateExperimentResFromObject(obj: CreateExperimentRes.AsObject | undefined): CreateExperimentRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateExperimentRes();
	message.setExperiment(ExperimentDTOFromObject(obj.experiment));
	return message;
}

function DeleteExperimentResFromObject(obj: DeleteExperimentRes.AsObject | undefined): DeleteExperimentRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteExperimentRes();
	message.setId(obj.id);
	return message;
}

function CreateExperimentJobResFromObject(obj: CreateExperimentJobRes.AsObject | undefined): CreateExperimentJobRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateExperimentJobRes();
	message.setExperimentJob(ExperimentJobDTOFromObject(obj.experimentJob));
	return message;
}

function ExperimentJobDTOFromObject(obj: ExperimentJobDTO.AsObject | undefined): ExperimentJobDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ExperimentJobDTO();
	message.setId(obj.id);
	message.setTemplate(obj.template);
	message.setOutputType(obj.outputType);
	(obj.experimentJobInputs || [])
		.map((item) => ExperimentJobInputDTOFromObject(item))
		.forEach((item) => message.addExperimentJobInputs(item));
	message.setExperimentJobOutput(ExperimentJobOutputDTOFromObject(obj.experimentJobOutput));
	return message;
}

function ListExperimentJobsResFromObject(obj: ListExperimentJobsRes.AsObject | undefined): ListExperimentJobsRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListExperimentJobsRes();
	(obj.items || [])
		.map((item) => ExperimentJobDTOFromObject(item))
		.forEach((item) => message.addItems(item));
	message.setPageResult(PaginationResultDTOFromObject(obj.pageResult));
	return message;
}

function PublishExperimentJobIDResFromObject(obj: PublishExperimentJobIDRes.AsObject | undefined): PublishExperimentJobIDRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new PublishExperimentJobIDRes();
	message.setExperimentJobId(obj.experimentJobId);
	return message;
}

function RetrieveExperimentJobResFromObject(obj: RetrieveExperimentJobRes.AsObject | undefined): RetrieveExperimentJobRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new RetrieveExperimentJobRes();
	message.setExperimentJob(ExperimentJobDTOFromObject(obj.experimentJob));
	return message;
}

function SubmitExperimentJobResultResFromObject(obj: SubmitExperimentJobResultRes.AsObject | undefined): SubmitExperimentJobResultRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new SubmitExperimentJobResultRes();
	message.setExperimentJob(ExperimentJobDTOFromObject(obj.experimentJob));
	return message;
}

function RetrieveExperimentJobResultResFromObject(obj: RetrieveExperimentJobResultRes.AsObject | undefined): RetrieveExperimentJobResultRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new RetrieveExperimentJobResultRes();
	message.setExperimentJobOutput(ExperimentJobOutputDTOFromObject(obj.experimentJobOutput));
	return message;
}

function RetrieveExperimentScriptResFromObject(obj: RetrieveExperimentScriptRes.AsObject | undefined): RetrieveExperimentScriptRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new RetrieveExperimentScriptRes();
	message.setTemplate(ExperimentScriptTemplateDTOFromObject(obj.template));
	return message;
}

function RetrieveAllExperimentScriptResFromObject(obj: RetrieveAllExperimentScriptRes.AsObject | undefined): RetrieveAllExperimentScriptRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new RetrieveAllExperimentScriptRes();
	(obj.templates || [])
		.map((item) => ExperimentScriptTemplateDTOFromObject(item))
		.forEach((item) => message.addTemplates(item));
	return message;
}

function CreateExperimentScriptResFromObject(obj: CreateExperimentScriptRes.AsObject | undefined): CreateExperimentScriptRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateExperimentScriptRes();
	message.setScript(ExperimentScriptTemplateDTOFromObject(obj.script));
	return message;
}

function CreateExperimentWithScriptsResFromObject(obj: CreateExperimentWithScriptsRes.AsObject | undefined): CreateExperimentWithScriptsRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateExperimentWithScriptsRes();
	message.setExperiment(ExperimentDTOFromObject(obj.experiment));
	return message;
}

function TestReplyResFromObject(obj: TestReplyRes.AsObject | undefined): TestReplyRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new TestReplyRes();
	message.setText(obj.text);
	return message;
}

