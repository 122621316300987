import * as React from 'react';
import { classes } from '../../../utils/Classes';
import { container } from './Container.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export function Container(props: Props) {
  return <div className={classes(container, props.className)}>{props.children}</div>;
}
