import React from 'react';
import styles from './Logo.module.scss';

export enum LogoColor {
  blue,
  white,
  original,
}

interface Props {
  hideText?: boolean;
  textColor?: string;
  color?: LogoColor;
  className?: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}

// tslint:disable-next-line
export function Logo(props: Props) {
  const colors = {
    colorYTop: '',
    colorYBottom: '',
    colorZFront: '',
    colorZBack: '',
    colorXLeft: '',
    colorXRight: '',
    colorBlitzm: '',
    colorSystems: '',
  };

  switch (props.color) {
    case LogoColor.blue:
      colors.colorYTop = '#1221D3';
      colors.colorYBottom = colors.colorYTop;
      colors.colorXRight = '#D0D3F6';
      colors.colorXLeft = colors.colorXRight;
      colors.colorZFront = '#717AE4';
      colors.colorZBack = colors.colorZFront;
      colors.colorBlitzm = colors.colorYTop;
      colors.colorSystems = colors.colorYTop;
      break;

    case LogoColor.white:
      colors.colorYTop = 'white';
      colors.colorYBottom = colors.colorYTop;
      colors.colorXRight = '#efefef';
      colors.colorXLeft = colors.colorXRight;
      colors.colorZFront = '#c1c1c1';
      colors.colorZBack = colors.colorZFront;
      colors.colorBlitzm = colors.colorYTop;
      colors.colorSystems = colors.colorYTop;
      break;

    case LogoColor.original:
    default:
      colors.colorYTop = '#c53631';
      colors.colorYBottom = '#41100e';
      colors.colorXRight = '#5baa45';
      colors.colorXLeft = '#162c10';
      colors.colorZFront = '#1428ca';
      colors.colorZBack = '#1428ca';
      colors.colorBlitzm = '#000000';
      colors.colorSystems = '#000000';
      break;
  }

  if (props.textColor) {
    colors.colorBlitzm = props.textColor;
    colors.colorSystems = props.textColor;
  }

  return (
    <div>
      <svg
        className={`${styles.logo} ${props.className}`}
        id="Layer_1"
        viewBox={`0 0 ${props.hideText ? 255 : 551.3} 234`}
        preserveAspectRatio="xMinYMin meet"
        height={props.height || 80}
        width={props.width}
        style={props.style}
      >
        <path fill={colors.colorYTop} d="M174.9 0L72.1 44.5l3.7 99 50.1-27.1.4-61.4 48.6 27.4z" />
        <path fill={colors.colorXLeft} d="M59 100.1L55.1 22l26 13.3L67 41.4l1.9 54.2z" />
        <path
          fill={colors.colorZFront}
          d="M0 136.1l89.3 62.2 82.8-49.6-42.4-29-58.3 31.9-2.5-50.9z"
        />
        <path fill={colors.colorYBottom} d="M89.9 203.5l-12-7.9L79 234l68.8-44.6-16.6-10.7z" />
        <path
          fill={colors.colorXRight}
          d="M135.3 176.2l45-27.7-49.6-32.8.8-51.8 81.6 45-6.7 113.5z"
        />
        <path fill={colors.colorZBack} d="M216.3 121.7l1.3-16-38.1-21.5V68.4l66.6 34.1z" />
        <path
          fill={colors.colorBlitzm}
          d="M269.5 115.1V47.4H300c9.5 0 22.1 2.3 22.1 18.7 0 6-2.2 10.9-5.8 13.3 5.5 1.6 8.1 8.3 8.1 15.4 0 17.1-11.5
          20.3-23.9 20.3h-31zm16-53.4v11.6h13.6c4.1 0 6.7-1.1 6.7-6 0-4.7-2.5-5.6-6.7-5.6h-13.6zm14.4 39c3.3 0 8.2-.3
          8.2-6.3 0-6.2-4.3-6.8-7.9-6.8h-14.7v13.1h14.4zM330.6 45.7h15.6v69.4h-15.6V45.7zM370.7
          63.2h-16V47.4h16v15.8zM355 69.1h15.6v46H355v-46zM375.9 69.1h8.4V55.2h15.6v13.9h13.9v13h-13.9v15.4c0 3.6 1.8
          4.9 4.8 5.2 3.1.3 6.7-1.1 8.2-2.1l1.1 14.1c-1.8.8-5.1 1.7-9.8 1.7-15.5
          0-19.9-8.8-19.9-16.7V82.1h-8.4v-13zM416.5 104.6l22.9-21.7H418V69.1h44.3v10.5l-22.9
          21.7h23.9v13.8h-46.9v-10.5zM482.5 74c2.9-3.9 8.1-6.3 14.1-6.3 6.4 0 11.5 2.1 14.6 6.5 4.6-4.7
          10.4-6.5 16.1-6.5 11 0 17.6 5.7 17.6 17.7v29.7h-15.6v-28c0-3.6-2.4-5.3-6.2-5.3-4.5 0-7.3 2.5-8.8
          4.3V115h-15.6V87.1c0-3.6-2.4-5.3-6.2-5.3-4.5 0-7.3 2.5-8.8 4.3V115H468V82.4l-.9-13.4h14.3l1.1 5z"
        />
        <path
          fill={colors.colorSystems}
          d="M297.2 156.6c0-5-2.6-7.1-11.4-8-8-.7-15.7-2.5-15.8-11.6-.1-9 7.2-13 15.6-13 11.4 0 16.2 5 17.2
          12.2l-5.8.6c-.8-5.4-4.2-7.6-11.3-7.6-3.6.1-9.9 1.3-9.8 7.7 0 3.9 1.9 5.6 10.1 6.5 10.7 1 16.8 3 16.9 13 0
          9.5-8.4 13.6-17.1 13.6-11.7 0-16.4-5-17.4-12.5l5.8-.6c.9 5.6 4.4 7.8 11.6 7.8 5.3.2 11.4-1.9 11.4-8.1zM326.5
          146l5.1-8.1 8.5-13.1h6.6l-17.4 26.1v18.6h-5.7v-18.6l-17.4-26.1h6.6l8.5 13.1 5.2 8.1zM376.2
          156.6c0-5-2.6-7.1-11.4-8-8-.7-15.7-2.5-15.8-11.6-.1-9 7.2-13 15.6-13 11.4 0
          16.2 5 17.2 12.2l-5.8.6c-.8-5.4-4.2-7.6-11.3-7.6-3.6.1-9.9 1.3-9.8 7.7 0 3.9 1.9 5.6 10.1 6.5 10.7 1 16.8 3
          16.9 13 0 9.5-8.4 13.6-17.1 13.6-11.7 0-16.4-5-17.4-12.5l5.8-.6c.9 5.6 4.4 7.8 11.6 7.8 5.3.2 11.4-1.9
          11.4-8.1zM405.3 169.5h-5.7v-39.4h-13.8v-5.3h33.3v5.3h-13.8v39.4zM455
          124.8v5.3h-25V144h22.6v5.2H430v15h26.2v5.3h-31.9v-44.7H455zM482.5
          169.5l-10.8-26.1-2.8-7.4.2 17.1v16.3h-5.6v-44.7h6.2l11.7 28.2 3.6 9.4 3.6-9.4
          11.7-28.2h6.2v44.7h-5.6v-16.3l.2-17.1-2.8 7.4-10.8 26.1h-5zM542.3
          156.6c0-5-2.6-7.1-11.4-8-8-.7-15.7-2.5-15.8-11.6-.1-9 7.2-13 15.6-13 11.4 0 16.2 5 17.2
          12.2l-5.8.6c-.8-5.4-4.2-7.6-11.3-7.6-3.6.1-9.9 1.3-9.8 7.7 0 3.9 1.9 5.6 10.1 6.5 10.7 1 16.8 3 16.9 13 0
          9.5-8.4 13.6-17.1 13.6-11.7 0-16.4-5-17.4-12.5l5.8-.6c.9 5.6 4.4 7.8 11.6 7.8 5.3.2 11.4-1.9 11.4-8.1z"
        />
      </svg>
    </div>
  );
}
