import React, { useState } from 'react';
import styles from './Login.module.scss';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { userStore } from '../../stores/UserStore';
import { Redirect } from 'react-router-dom';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState('');

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    userStore.login(email, password).then(() => setRedirect('/dashboard'));
  }

  return redirect !== '' ? (
    <Redirect to={redirect} />
  ) : (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.description}>Log in</div>
        <Box boxShadow={3}>
          <div className={styles.form}>
            <div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                autoFocus
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </div>
            <div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </div>
            <div className={styles.login}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Login
              </Button>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};
