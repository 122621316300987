import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar, Box, Divider, Drawer, List, Typography } from '@material-ui/core';

const user = {
  avatar: '',
  jobTitle: 'Admin',
  name: 'John Smith',
};

export function SideBar() {
  return (
    <Drawer variant="permanent" anchor="left">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar component={Link} src={user.avatar} to="/account" />
        <Typography color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>

      <Divider />
      <List>
        <ListItem button component={Link} to="/dashboard">
          <ListItemText primary="Experiments" />
        </ListItem>
        <ListItem button component={Link} to="/devices">
          <ListItemText primary="Devices" />
        </ListItem>
        <ListItem button component={Link} to="/scripts">
          <ListItemText primary="Scripts" />
        </ListItem>
        <ListItem button component={Link} to="/history">
          <ListItemText primary="History" />
        </ListItem>
        <ListItem button component={Link} to="/groups">
          <ListItemText primary="Groups" />
        </ListItem>
      </List>
      <Divider />
    </Drawer>
  );
}
