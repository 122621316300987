import * as React from 'react';
import Router from '../navigation/Router';
import { Heading1 } from '../components/ui-kit/typography/Typography';
import { Container } from '../components/ui-kit/container/Container';

export default function App() {
  return (
    <ErrorBoundary>
      <Router />
    </ErrorBoundary>
  );
}
interface IErrorBoundaryState {
  crashed: boolean;
  error?: Error;
}

class ErrorBoundary extends React.Component<unknown, IErrorBoundaryState> {
  state: IErrorBoundaryState = {
    crashed: false,
    error: undefined,
  };

  static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { crashed: true, error };
  }

  componentDidCatch(error: Error) {
    // eslint-disable-next-line no-console
    console.log('Error caught in Error Boundary', error);
  }

  render() {
    if (this.state.crashed) {
      return (
        <Container>
          <Heading1 center>Something went wrong</Heading1>
        </Container>
      );
    }
    return <Router />;
  }
}
