import React, { useEffect, useState } from 'react';
import { TopBar } from '../../components/TopBar/TopBar';
import { SideBar } from '../../components/SideBar/SideBar';
import { ExperimentSheet } from './ExperimentSheet/ExperimentSheet';
import { ExperimentInterface } from './ExperimentInterface/ExperimentInterface';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { experimentService } from '../../services/api/ExperimentsService';
import { experimentStore } from '../../stores/ExperimentStore';
import { useParams } from 'react-router-dom';
import styles from './Experiment.scss';

export const Experiment = () => {
  const { id } = useParams<{ id: string }>();

  const [isLoading, setLoading] = useState(true);

  async function initialiseExperiment(experimentId: string) {
    await experimentStore
      .resetAll()
      .then(() => experimentStore.setExperimentId(experimentId))
      .then(() => experimentService.retrieveExperiment())
      .then(() => experimentService.retrieveAllExperimentScripts());
    if (experimentStore.experiment !== undefined) {
      setLoading(false);
    }
    return experimentStore.experiment;
  }

  useEffect(() => {
    initialiseExperiment(id);
  }, []);

  return isLoading ? (
    <div className={styles.root}>
      <TopBar></TopBar>
      <SideBar />
    </div>
  ) : (
    <div className={styles.root}>
      <TopBar infoText={'Experiment'}></TopBar>
      <SideBar />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <Paper className={styles.experimentSheet}>
            <ExperimentSheet
              title={experimentStore.experiment!.title}
              description={experimentStore.experiment!.description}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper className={styles.experimentInterface}>
            <ExperimentInterface />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
