import * as React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Dashboard } from '../pages/dashboard/Dashboard';
import { Experiment } from '../pages/experiment/Experiment';
import { Login } from '../pages/login/Login';
import { CreateExperiment } from '../pages/create/CreateExperiment';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/" component={WaitingComponent(Home)} /> */}
        <Redirect exact from="/" to="/login" />
        <Route exact path="/dashboard" component={Dashboard} />
        {/* <Route exact path="/experiment" component={Experiment} /> */}
        <Route exact path="/experiment/:id" component={Experiment} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/create" component={CreateExperiment} />
      </Switch>
    </BrowserRouter>
  );
}
