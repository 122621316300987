export interface NullChart {}
export const chartDataInit: NullChart[] = [
  ['', { role: 'annotation' }],
  ['', ''],
];

export function formatData(rawData: string | undefined) {
  if (rawData !== undefined) {
    let readings: (string | number)[][] = [['Time', 'Reading']];
    readings = readings.concat(
      rawData
        .split('\n')
        .filter(function (list) {
          return list !== '';
        })
        .map((x) => x.split(' '))
        .map((x) => [String(256 - Number(x[2])), Number(x[1])]),
    );
    readings = readings.filter(function (list) {
      return list !== null;
    });
    return readings;
  } else {
    return chartDataInit;
  }
}
