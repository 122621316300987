// Generated by protoc-gen-grpc-ts-web. DO NOT EDIT!
/* eslint-disable */
/* tslint:disable */

import * as jspb from 'google-protobuf';
import * as grpcWeb from 'grpc-web';

export class CoreService {

	private client_ = new grpcWeb.GrpcWebClientBase({
		format: 'text',
	});

	constructor(
		private hostname: string,
		private defaultMetadata?: () => grpcWeb.Metadata,
	) { }

}




export declare namespace PaginationRequestDTO {
	export type AsObject = {
		page: number,
		pageSize: number,
	}
}

export class PaginationRequestDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, PaginationRequestDTO.repeatedFields_, null);
	}


	getPage(): number {
		return jspb.Message.getFieldWithDefault(this, 1, 0);
	}

	setPage(value: number): void {
		(jspb.Message as any).setProto3IntField(this, 1, value);
	}

	getPageSize(): number {
		return jspb.Message.getFieldWithDefault(this, 2, 0);
	}

	setPageSize(value: number): void {
		(jspb.Message as any).setProto3IntField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		PaginationRequestDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): PaginationRequestDTO.AsObject {
		let f: any;
		return {page: this.getPage(),
			pageSize: this.getPageSize(),
			
		};
	}

	static serializeBinaryToWriter(message: PaginationRequestDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != 0) {
			writer.writeInt32(1, field1);
		}
		const field2 = message.getPageSize();
		if (field2 != 0) {
			writer.writeInt32(2, field2);
		}
	}

	static deserializeBinary(bytes: Uint8Array): PaginationRequestDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new PaginationRequestDTO();
		return PaginationRequestDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: PaginationRequestDTO, reader: jspb.BinaryReader): PaginationRequestDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readInt32()
				message.setPage(field1);
				break;
			case 2:
				const field2 = reader.readInt32()
				message.setPageSize(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace PaginationResultDTO {
	export type AsObject = {
		page: number,
		total: number,
	}
}

export class PaginationResultDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, PaginationResultDTO.repeatedFields_, null);
	}


	getPage(): number {
		return jspb.Message.getFieldWithDefault(this, 1, 0);
	}

	setPage(value: number): void {
		(jspb.Message as any).setProto3IntField(this, 1, value);
	}

	getTotal(): number {
		return jspb.Message.getFieldWithDefault(this, 2, 0);
	}

	setTotal(value: number): void {
		(jspb.Message as any).setProto3IntField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		PaginationResultDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): PaginationResultDTO.AsObject {
		let f: any;
		return {page: this.getPage(),
			total: this.getTotal(),
			
		};
	}

	static serializeBinaryToWriter(message: PaginationResultDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != 0) {
			writer.writeInt32(1, field1);
		}
		const field2 = message.getTotal();
		if (field2 != 0) {
			writer.writeInt32(2, field2);
		}
	}

	static deserializeBinary(bytes: Uint8Array): PaginationResultDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new PaginationResultDTO();
		return PaginationResultDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: PaginationResultDTO, reader: jspb.BinaryReader): PaginationResultDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readInt32()
				message.setPage(field1);
				break;
			case 2:
				const field2 = reader.readInt32()
				message.setTotal(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}


function PaginationRequestDTOFromObject(obj: PaginationRequestDTO.AsObject | undefined): PaginationRequestDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new PaginationRequestDTO();
	message.setPage(obj.page);
	message.setPageSize(obj.pageSize);
	return message;
}

function PaginationResultDTOFromObject(obj: PaginationResultDTO.AsObject | undefined): PaginationResultDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new PaginationResultDTO();
	message.setPage(obj.page);
	message.setTotal(obj.total);
	return message;
}

