import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { experimentStore } from '../../../stores/ExperimentStore';
import { experimentService } from '../../../services/api/ExperimentsService';
import { ExperimentJobInputDTO, ParameterTypeDTO } from '../../../sdk/Experiment_pb';

interface Props {
  name: string;
  template: string;
  outputType: ParameterTypeDTO;
  inputs: Array<ExperimentJobInputDTO.AsObject>;
  sendScript: (
    template: string,
    outputType: ParameterTypeDTO,
    inputs: Array<ExperimentJobInputDTO.AsObject>,
  ) => void;
  submitted: boolean;
}

export const InputInterface = (props: Props) => {
  const [inputs, setInputs] = useState(props.inputs);

  const updateInput = (
    prop: string,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    idx: number,
  ) => {
    const old = inputs[idx];
    const updated = { ...old, [prop]: event.target.value };
    const clone = [...inputs];
    clone[idx] = updated;
    setInputs(clone);
  };

  return (
    <Grid>
      {inputs.map((input, idx) => (
        <TextField
          variant="outlined"
          size="small"
          style={{ width: 150 }}
          label={input.key}
          value={input.value}
          key={idx}
          onChange={(event) => updateInput('value', event, idx)}
        >
          {input.value}
        </TextField>
      ))}
      <Button
        variant="contained"
        size="large"
        disabled={props.submitted}
        color="primary"
        onClick={() => {
          props.sendScript(props.template, props.outputType, inputs);
        }}
      >
        {props.name}
      </Button>
    </Grid>
  );
};
