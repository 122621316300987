import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import styles from '../CreateExperiment.scss';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { ExperimentJobInputDTO, ParameterTypeDTO } from '../../../sdk/Experiment_pb';

interface Props {
  name: string;
  template: string;
  outputType: ParameterTypeDTO;
  inputs: Array<ExperimentJobInputDTO.AsObject>;
  scriptUpdate: (prop: string, value: string | Array<ExperimentJobInputDTO.AsObject>) => void; //TODO: figure out type of onchange
}

export const CreateScript = (props: Props) => {
  const [name, setName] = useState(props.name);
  const [template, setTemplate] = useState(props.template);
  const [outputType, setOutputType] = useState(props.outputType);
  const [inputs, setInputs] = useState(props.inputs);

  useEffect(() => {
    props.scriptUpdate('inputs', inputs);
  }, [inputs]);

  const updateInput = (prop: string, value: string, idx: number) => {
    const old = inputs[idx];
    const updated = { ...old, [prop]: value };
    const clone = [...inputs];
    clone[idx] = updated;
    setInputs(clone);
  };

  const newInput = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const newinput: ExperimentJobInputDTO.AsObject = {
      key: '',
      value: '',
      parameterType: 2,
    };
    setInputs([...inputs, newinput]);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={6}>
        <Paper className={styles.newExperiment}>
          <Box className={styles.experimentEntry}>
            <Box fontSize={16}>Name</Box>
            <TextField
              variant="outlined"
              size="small"
              style={{ width: 300 }}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                props.scriptUpdate('name', event.target.value)
              }
            />
          </Box>
          <Box className={styles.experimentEntry}>
            <Box fontSize={16}>Template</Box>
            <TextField
              variant="outlined"
              size="small"
              style={{ width: 300 }}
              multiline
              rows={4}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                props.scriptUpdate('template', event.target.value)
              }
            />
          </Box>
          <Box fontSize={16} className={styles.experimentEntry}>
            Inputs
          </Box>
          <Box className={styles.experimentEntry}>
            {inputs.map((input, idx) => (
              <Grid container spacing={2} key={idx}>
                <Grid item xs={6}>
                  <Box fontSize={14}>Variable</Box>
                  <TextField
                    variant="outlined"
                    size="small"
                    style={{ width: 150 }}
                    onChange={(event) => updateInput('key', event.target.value, idx)}
                  >
                    {input.value}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <Box fontSize={14}>Value</Box>
                  <TextField
                    variant="outlined"
                    size="small"
                    style={{ width: 150 }}
                    onChange={(event) => updateInput('value', event.target.value, idx)}
                  >
                    {input.value}
                  </TextField>
                </Grid>
              </Grid>
            ))}
          </Box>
          <Box className={styles.experimentEntry}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={(event) => newInput(event)}
            >
              {'Add variable'}
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
