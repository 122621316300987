import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { experimentStore } from '../../../stores/ExperimentStore';
import { experimentService } from '../../../services/api/ExperimentsService';
import styles from './ExperimentInterface.module.scss';
import { Chart } from 'react-google-charts';
import { formatData } from '../../../utils/ChartData';
import { InputInterface } from '../InputInterface/InputInterface';
import { ExperimentJobInputDTO, ParameterTypeDTO } from '../../../sdk/Experiment_pb';
import { chartDataInit, NullChart } from '../../../utils/ChartData';

export const ExperimentInterface = () => {
  const [scriptTemplates, setScriptTemplates] = useState(experimentStore.scripts);
  const [submitted, setSubmitted] = useState(false);
  const [rawData, setRawData] = useState('');
  const [chartData, setChartData] = useState<(number | string)[][] | NullChart>(chartDataInit);

  const sendScript = (
    template: string,
    outputType: ParameterTypeDTO,
    inputs: Array<ExperimentJobInputDTO.AsObject>,
  ) => {
    setSubmitted(true);
    experimentService.submitExperimentJob(template, outputType, inputs);
    let attempts = 0;
    const interval = setInterval(() => {
      if (attempts > 4) {
        clearInterval(interval);
        setSubmitted(false);
      } else {
        attempts++;
        experimentService.retrieveExperimentJobResult().then(function (response) {
          if (response.experimentJobOutput !== undefined) {
            setRawData(response.experimentJobOutput.rawText);
            setChartData(formatData(response.experimentJobOutput.rawText));
            clearInterval(interval);
            setSubmitted(false);
          }
        });
      }
    }, 5000);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Card variant="outlined" className={styles.chartArea}>
            <Box m={2}>
              <h2>Experiment Result</h2>
            </Box>
            <Chart width={800} height={400} chartType="LineChart" data={chartData} />
          </Card>
          <Card variant="outlined" className={styles.chartArea}>
            <Box m={2}>
              <h2>Raw Output</h2>
            </Box>
            <Box m={2}>{rawData}</Box>
          </Card>
        </Grid>
        <Grid item xs={2} sm={4}>
          <Box className={styles.inputArea}>
            {scriptTemplates.map((script) => (
              <InputInterface
                key={script.id}
                name={script.name}
                template={script.template}
                outputType={script.outputType}
                inputs={script.inputs}
                sendScript={sendScript}
                submitted={submitted}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
