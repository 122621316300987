import React, { useState } from 'react';
import styles from './TopBar.module.scss';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Logo } from '../Logo/Logo';
import { LogoColor } from '../Logo/Logo';
import { userStore } from '../../stores/UserStore';
import { Redirect } from 'react-router-dom';

interface Props {
  infoText?: string;
}

export function TopBar(props: Props) {
  const [redirect, setRedirect] = useState('');

  return redirect !== '' ? (
    <Redirect to={redirect} />
  ) : (
    <div className={styles.toolbar}>
      <Box display="flex">
        <Box>
          <Logo className={styles.logo} color={LogoColor.original}></Logo>
        </Box>
        <Box flexGrow={1} className={styles.text}>
          <span>{props.infoText}</span>
        </Box>
        <div>
          <Box className={styles.logIn}>
            {userStore.isAuthenticated ? (
              <div>
                <Button
                  onClick={() => userStore.logout().then(() => setRedirect('/login'))}
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  {'Log out'}
                </Button>
              </div>
            ) : (
              <Button href="/login" color="primary" size="large">
                {'Log in'}
              </Button>
            )}
          </Box>
        </div>
      </Box>
    </div>
  );
}
