import { observable, action, reaction, computed } from 'mobx';
import { UsersService } from '../sdk/Users_pb';
import { environment } from '../utils/Environment';

const apiUrl = environment.apiUrl;

class UserStore {
  private userService = new UsersService(apiUrl, () => ({}));

  @observable
  isLoading = false;

  @observable
  currentUser = window.localStorage.getItem('user');

  @computed get isAuthenticated() {
    return this.currentUser !== null;
  }

  constructor() {
    reaction(
      () => this.currentUser,
      (user) => {
        if (user) {
          window.localStorage.setItem('user', user);
        } else {
          window.localStorage.removeItem('user');
        }
      },
    );
  }

  @action
  async login(email: string, password: string) {
    let response;
    this.isLoading = true;
    try {
      response = await this.userService.loginUser({ email, password });
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.isLoading = false;
    }
    if (response.currentUser) {
      this.currentUser = response.currentUser.email;
    }
    return Promise.resolve(response);
  }

  @action
  async logout() {
    this.isLoading = true;
    try {
      await this.userService.logoutUser({});
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.isLoading = false;
    }
    this.currentUser = '';
    return Promise.resolve();
  }
}

export const userStore = new UserStore();
