import { action, observable, makeObservable } from 'mobx';
import {
  ExperimentDTO,
  ExperimentJobDTO,
  ExperimentJobOutputDTO,
  ExperimentScriptTemplateDTO,
} from '../sdk/Experiment_pb';
import { chartDataInit, NullChart } from '../utils/ChartData';

//TODO: Delet ths. All of these things are local properties.
class ExperimentStore {
  constructor() {
    makeObservable(this, {
      page: observable,
      pageSize: observable,
      totalCount: observable,
      isLoading: observable,
      experiments: observable,
      experimentId: observable,
      experiment: observable,
      scripts: observable,
      experimentJob: observable,
      experimentJobId: observable,
      experimentJobOutput: observable,
      setPage: action,
      setPageSize: action,
      setTotalCount: action,
      setIsLoading: action,
      setExperiments: action,
      setExperiment: action,
    });
  }
  page = 0;
  pageSize = 10;
  totalCount = 0;
  isLoading = false;
  experiments: Array<ExperimentDTO.AsObject> = [];
  experimentId = '';
  experiment: ExperimentDTO.AsObject | undefined = {
    id: '',
    title: '',
    author: '',
    createdDate: '',
    description: '',
    picDeviceId: '',
    serverID: '',
  };
  scripts: Array<ExperimentScriptTemplateDTO.AsObject> = [];
  experimentJobId = '';
  experimentJob?: ExperimentJobDTO.AsObject = undefined;
  experimentJobOutput?: ExperimentJobOutputDTO.AsObject = undefined;
  experimentJobSubmitted = false;

  async setPage(page: number) {
    this.page = page;
  }
  async setPageSize(pageSize: number) {
    this.pageSize = pageSize;
  }
  async setTotalCount(totalCount: number) {
    this.totalCount = totalCount;
  }
  async setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
  async setExperiments(experiments: Array<ExperimentDTO.AsObject>) {
    this.experiments = experiments;
  }
  async setExperimentId(experimentId: string) {
    this.experimentId = experimentId;
  }
  async setExperiment(experiment: ExperimentDTO.AsObject | undefined) {
    this.experiment = experiment;
  }
  async setScripts(scripts: Array<ExperimentScriptTemplateDTO.AsObject>) {
    this.scripts = scripts;
  }
  async setExperimentJob(experimentJob: ExperimentJobDTO.AsObject) {
    this.experimentJob = experimentJob;
  }
  async setExperimentJobId(experimentJobId: string) {
    this.experimentJobId = experimentJobId;
  }
  async setExperimentJobOutput(experimentJobOutput: ExperimentJobOutputDTO.AsObject) {
    this.experimentJobOutput = experimentJobOutput;
  }
  async resetAll() {
    this.scripts = [];
    this.experimentJobId = '';
    this.experimentJob = undefined;
    this.experimentJobOutput = undefined;
    this.experimentJobSubmitted = false;
  }
}

export const experimentStore = new ExperimentStore();
